import { NextRouter } from 'next/router'

import { userGetProfile } from '~/api-client/food_api/user'
import {
  ChatMessage,
  ChatSpace,
  ChatSpaceDirectMessage,
  ChatSpaceEvent,
  ChatSpaceGroup,
  Dinner,
  Image,
} from '~/api-definition/definition'
import { ChatMessageReactionType } from '~/api-definition/enumTypes'
import { config } from '~/config'
import { Application } from '~/provider/ApplicationProvider'

import { formatDate, formatTime } from './date'

export const chatMessageReactionTypes: ChatMessageReactionType[] = [
  'thumbs_up',
  'heart',
  'pray',
  'blush_face',
  'laughing_face',
  'open_mouth_face',
  'bow',
  'pleading_face',
  'thinking_face',
  'drooling_face',
]

export const chatReactionImage = (reactionType: ChatMessageReactionType) => {
  return `/images/reaction-${reactionType}.svg`
}

export const chatLatestMessageOrEventAt = (chatSpace: ChatSpace) => {
  if (chatSpace.latestMessage == null && chatSpace.latestEvent == null) {
    return undefined
  }

  let latestMessageDate: Date
  if (chatSpace.latestMessage == null) {
    if (chatSpace.latestEvent == null) {
      return undefined
    } else {
      latestMessageDate = new Date(chatSpace.latestEvent.createdAtMs)
    }
  } else {
    if (chatSpace.latestEvent == null) {
      latestMessageDate = new Date(chatSpace.latestMessage.sentAtMs)
    } else {
      latestMessageDate = new Date(
        Math.max(
          chatSpace.latestMessage.sentAtMs,
          chatSpace.latestEvent.createdAtMs
        )
      )
    }
  }

  const today = new Date()
  if (today.getFullYear() === latestMessageDate.getFullYear()) {
    if (today.getMonth() === latestMessageDate.getMonth()) {
      const diffDays = today.getDate() - latestMessageDate.getDate()
      if (diffDays === 0) {
        return formatTime({ msec: latestMessageDate.getTime() })
      } else if (diffDays === 1) {
        return '昨日'
      } else if (diffDays === 2) {
        return '一昨日'
      }
    }
  }

  return formatDate(
    { msec: latestMessageDate.getTime() },
    { sep: 'slash', omitCurrentYear: true }
  )
}

export const chatGroupName = (chatSpaceGroup: ChatSpaceGroup | undefined) => {
  return chatSpaceGroup?.groupName !== ''
    ? chatSpaceGroup?.groupName
    : chatSpaceGroup.members.map((member) => member.user.displayName).join(', ')
}

export const chatGroupMemberCountText = (
  chatSpaceGroup: ChatSpaceGroup | undefined
) => {
  return chatSpaceGroup !== null
    ? `（${chatSpaceGroup?.members.length}）`
    : undefined
}

export const chatDmName = (
  chatSpaceDirectMessage: ChatSpaceDirectMessage | undefined
) => {
  return chatSpaceDirectMessage?.dmName != null &&
    chatSpaceDirectMessage?.dmName !== ''
    ? chatSpaceDirectMessage.dmName
    : chatSpaceDirectMessage?.pairMember.user.displayName
}

export const chatMessageHtmlId = (id: string) => {
  return `message-${id}`
}

export const chatScrollToMessageElement = (id: string) => {
  console.log(chatMessageHtmlId(id))
  console.log(document.querySelector(chatMessageHtmlId(id)))

  document
    .querySelector('#' + chatMessageHtmlId(id))
    ?.scrollIntoView({ behavior: 'smooth' })
}

export const chatMessageAsSummarizedText = (message: ChatMessage) => {
  switch (message.messageType) {
    case 'text':
      return message.text
    case 'images':
      return `${message.fromUser.displayName}さんが画像を送信しました`
    case 'gift':
      return `${message.fromUser.displayName}さんがギフトを贈りました`
  }
}

export const chatSpaceEventAsSummarizedText = (
  event: ChatSpaceEvent,
  dinner?: Dinner
) => {
  switch (event.eventType) {
    case 'dinner_group_created':
      return '食事会グループが作成されました'
    case 'dinner_scheduled_hour':
      return dinner
        ? `本日、${formatTime(
            {
              sec: dinner.scheduledAt,
            },
            { sep: 'japanese' }
          )}から食事会が始まります`
        : ''
    case 'dinner_scheduled_day':
      return '食事会の1日前になりました'
    case 'dinner_scheduled_week':
      return '食事会の1週間前になりました'
    case 'group_name_updated':
      return `${event.user?.displayName}さんがグループ名を「${event.groupName}」に設定しました`
    case 'member_joined':
      return `${event.user?.displayName}さんがグループに参加しました`
    case 'member_left':
      return `${event.user?.displayName}さんがグループを退出しました`
  }
}

export const chatSpaceStatusLine = (chatSpace: ChatSpace) => {
  if (
    chatSpace.latestMessage != null &&
    (chatSpace.latestEvent == null ||
      chatSpace.latestEvent.createdAtMs < chatSpace.latestMessage.sentAtMs)
  ) {
    return chatMessageAsSummarizedText(chatSpace.latestMessage)
  }
  if (chatSpace.latestEvent != null) {
    return chatSpaceEventAsSummarizedText(
      chatSpace.latestEvent,
      chatSpace.dinner
    )
  }
}

export const chatImageToSrc = (image: Image) => {
  return {
    try: image.imageUrl,
    fallback: config.noImageUrl.chatImage,
  }
}

export const haveDmChatSpace = async (
  accessToken: string,
  pairUserId: string
) => {
  const res = await userGetProfile({
    accessToken: accessToken,
    searchParams: {
      user_id: pairUserId,
    },
  })

  return res.user.haveDmChatSpace
}

export const goToChatGiftsPage = async (
  app: Application,
  router: NextRouter,
  chatSpace: ChatSpace
) => {
  switch (app.paymentType) {
    case 'web':
    case 'unsupported':
      await router.push(`/chat_spaces/${chatSpace.chatSpaceId}/gifts`)
      break
    case 'iap':
      if (chatSpace.group != null) {
        await router.push(`/chat_spaces/${chatSpace.chatSpaceId}/gifts`)
      } else {
        const dm = chatSpace.dm
        if (dm == null) return
        app.delegateSaleGiftsForChatSpace(
          chatSpace.chatSpaceId,
          dm.pairMember.user.userId,
          async () => {
            await router.push(`/chat_spaces/${chatSpace.chatSpaceId}`)
          }
        )
      }
      break
  }
}
