import React, { useEffect, useMemo, useState } from 'react'

import { AppProps } from 'next/app'
import Head from 'next/head'

import { cx } from '@linaria/core'
import * as Sentry from '@sentry/nextjs'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { SWRConfig } from 'swr'

import { isSuperFetchError } from '~/api-client'
import { parseErrorResponseText } from '~/api-client/food_api/error'
import { Modal } from '~/components/action/Modal'
import { config } from '~/config'
import { useFlutterCallbacks } from '~/libs/flutter'
import { ApplicationProvider } from '~/provider/ApplicationProvider'
import { DmNavigationProvider } from '~/provider/DmNavigationProvider'
import { ReportDialogProvider } from '~/provider/ReportDialogProvider'
import { SessionProvider, useSession } from '~/provider/SessionProvider'
import { UnreadBadgeProvider } from '~/provider/UnreadBadgeProvider'

import '../styles/datepicker.css'
import '../styles/globals.css'

const logError = (error: Error, info: { componentStack: string }) => {
  console.error(error, info)
  Sentry.captureException(error)
}

const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const [open, setOpen] = useState(true)

  const errorMessage = useMemo(() => {
    if (isSuperFetchError(error)) {
      return parseErrorResponseText(error.response).message
    } else {
      return '予期せぬエラーが発生しました。'
    }
  }, [error])

  return (
    <Modal
      isOpen={open}
      title={errorMessage}
      setIsOpen={async (v) => {
        if (!v) {
          setOpen(false)
          resetErrorBoundary()
        }
      }}
    />
  )
}

const MySWRConfig: React.FC<{
  children: React.ReactNode
  onError: (err: any) => void
}> = (props) => {
  const session = useSession()

  return (
    <SWRConfig
      value={{
        onError: async (error) => {
          if (isSuperFetchError(error)) {
            const res = parseErrorResponseText(error.response)
            if (res.errorType === 'token') {
              session.openLoginDialog()
              return
            } else if (res.errorType === 'soft_banned') {
              await session.logout()
              return
            }
          }
          props.onError(error)
        },
      }}
    >
      {props.children}
    </SWRConfig>
  )
}

const _MyApp: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const registerFlutterCallbacks = useFlutterCallbacks()

  useEffect(() => {
    registerFlutterCallbacks()
  }, [registerFlutterCallbacks])

  return <>{children}</>
}

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const [swrError, setSwrError] = useState<unknown | null>(null)

  const errorMessage = useMemo(() => {
    if (swrError == null) return null
    if (isSuperFetchError(swrError)) {
      return parseErrorResponseText(swrError.response).message
    } else {
      return '予期せぬエラーが発生しました。'
    }
  }, [swrError])

  useEffect(() => {
    setTimeout(() => {
      setSwrError(null)
    }, 5e3)
  }, [swrError])

  useEffect(() => {
    ;(async () => {
      if (config.foodEnv === 'sandbox') {
        const VConsole = (await import('vconsole')).default
        new VConsole()
      }
    })()
  }, [])

  return (
    <>
      <Head>
        <title>Foodies Prime</title>
        {/* ローディング画像の表示が遅いと本末転倒なので、preload する */}
        <link rel="preload" href={config.loadingImageUrl.main} as="image" />
        {/* TODO: 実機確認後、要調整 */}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0,viewport-fit=cover"
        />
      </Head>
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={logError}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <div
          className={cx('fixed p-4 w-full z-50', swrError == null && 'hidden')}
        >
          <div className=" bg-secondary text-white w-full px-3 py-2 rounded">
            {errorMessage}
          </div>
        </div>
        <ApplicationProvider>
          <SessionProvider>
            <MySWRConfig onError={(error) => setSwrError(error)}>
              <ReportDialogProvider>
                <DmNavigationProvider>
                  <UnreadBadgeProvider>
                    <_MyApp>
                      <Component {...pageProps} />
                    </_MyApp>
                  </UnreadBadgeProvider>
                </DmNavigationProvider>
              </ReportDialogProvider>
            </MySWRConfig>
          </SessionProvider>
        </ApplicationProvider>
      </ErrorBoundary>
    </>
  )
}

export default MyApp
