import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Button } from '~/components/action/Button'
import { Modal } from '~/components/action/Modal'

export type ApplicationMode = 'unsettled' | 'inapp' | 'web'
export type PaymentType = 'unsettled' | 'web' | 'iap' | 'unsupported'

export type Application = {
  mode: ApplicationMode
  locale?: string
  iapGiftEnabled?: boolean
  setIapGiftEnabled: (iapGiftEnabled: boolean) => void
  faqEnabled?: boolean
  setFaqEnabled: (faqEnabled: boolean) => void
  setLocale: (locale: string) => void
  setSaleGiftsResult: (result: 'purchased' | 'canceled' | 'error') => void
  paymentType: PaymentType
  delegateSignIn: () => Promise<void>
  delegateSaleGiftsForChatSpace: (
    chatSpaceId: string,
    toUserId: string,
    cb: () => void
  ) => void
  delegateSaleGiftsForTweet: (tweetId: string, cb: () => void) => void
  delegateSaleGiftsForReply: (tweetReplyId: string, cb: () => void) => void
  installedApps?: { outlook?: boolean }
  setInstalledApps: (apps: { outlook?: boolean }) => void
}

const ApplicationContext = createContext<Application>({
  mode: 'unsettled',
  iapGiftEnabled: undefined,
  setIapGiftEnabled: () => {},
  faqEnabled: undefined,
  setFaqEnabled: () => {},
  setLocale: () => {},
  setSaleGiftsResult: () => {},
  paymentType: 'unsettled',
  delegateSignIn: async () => {},
  delegateSaleGiftsForChatSpace: () => {},
  delegateSaleGiftsForTweet: () => {},
  delegateSaleGiftsForReply: () => {},
  setInstalledApps: () => {},
})

export const ApplicationProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [mode, setMode] = useState<ApplicationMode>('unsettled')
  const [locale, setLocale] = useState<string>()
  const [installedApps, setInstalledApps] = useState<{ outlook?: boolean }>({
    outlook: false,
  })

  const [iapGiftEnabled, setIapGiftEnabled] = useState<boolean>()
  const [faqEnabled, setFaqEnabled] = useState<boolean>()
  const [saleGiftsResult, setSaleGiftsResult] = useState<
    'purchased' | 'canceled' | 'error'
  >()
  const [saleGiftsCallback, setSaleGiftsCallback] = useState<() => void>()

  const delegateSignIn = useCallback(async () => {
    window?.inapp?.delegateSignIn?.()
  }, [])

  const delegateSaleGiftsForChatSpace = (
    chatSpaceId: string,
    toUserId: string,
    cb: () => void
  ) => {
    setSaleGiftsCallback(() => cb)
    window?.inapp?.delegateSaleGiftsForChatSpace?.(chatSpaceId, toUserId)
  }
  const delegateSaleGiftsForTweet = (tweetId: string, cb: () => void) => {
    setSaleGiftsCallback(() => cb)
    window?.inapp?.delegateSaleGiftsForTweet?.(tweetId)
  }
  const delegateSaleGiftsForReply = (tweetReplyId: string, cb: () => void) => {
    setSaleGiftsCallback(() => cb)
    window?.inapp?.delegateSaleGiftsForReply?.(tweetReplyId)
  }

  const paymentType = useMemo(() => {
    if (mode === 'web') {
      return 'web'
    }
    if (mode === 'inapp') {
      return iapGiftEnabled ? 'iap' : 'unsupported'
    }
    return 'unsettled'
  }, [iapGiftEnabled, mode])

  useEffect(() => {
    const mode = window?.flutter_inappwebview !== undefined ? 'inapp' : 'web'
    setMode(mode)
  }, [])

  return (
    <ApplicationContext.Provider
      value={{
        mode,
        locale,
        setLocale,
        setSaleGiftsResult,
        iapGiftEnabled,
        setIapGiftEnabled,
        faqEnabled,
        setFaqEnabled,
        paymentType,
        delegateSignIn,
        delegateSaleGiftsForChatSpace,
        delegateSaleGiftsForTweet,
        delegateSaleGiftsForReply,
        installedApps,
        setInstalledApps,
      }}
    >
      <Modal
        title={
          saleGiftsResult
            ? {
                purchased: '決済が完了しました',
                error: 'エラーが発生しました',
                canceled: '購入をキャンセルしました',
              }[saleGiftsResult]
            : undefined
        }
        isOpen={saleGiftsResult != null}
        actionButtons={
          <div className="flex justify-center mt-9">
            <Button
              type="button"
              variant="primary"
              onClick={async () => {
                if (saleGiftsCallback) {
                  saleGiftsCallback()
                  setSaleGiftsCallback(undefined)
                }
                setSaleGiftsResult(undefined)
              }}
            >
              OK
            </Button>
          </div>
        }
      />
      {props.children}
    </ApplicationContext.Provider>
  )
}

export const useApplication = () => {
  const ctx = useContext(ApplicationContext)
  return ctx
}

export const useApplicationMode = () => {
  const app = useApplication()
  return app.mode
}
