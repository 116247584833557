import camelcaseKeys from 'camelcase-keys'
import { Jsonifiable } from 'type-fest'

export const createSuperFetchError = async (args: {
  method: string
  url: string
  requestBody: any
  res: Response
}) => {
  const err: Record<string, any> = {
    isSupeFetchError: true,
    request: {
      method: args.method,
      url: args.url,
    },
    response: {
      status: args.res.status,
      body: await args.res.text(),
    },
  }
  if (process.env.NODE_ENV === 'development') {
    if (args.requestBody) {
      if (args.requestBody instanceof URLSearchParams) {
        err.request.body = args.requestBody.toString()
      } else {
        err.request.body = JSON.stringify(args.requestBody)
      }
    }
  }
  return err
}

export const superFetch = async <Response>(
  url: string,
  opts?:
    | {
        method: 'GET' | 'DELETE'
        headers?: HeadersInit
      }
    | {
        method: 'POST' | 'PUT' | 'PATCH'
        headers?: HeadersInit
      }
    | {
        method: 'POST' | 'PUT' | 'PATCH'
        contentType: 'application/json'
        body: Request & Jsonifiable
        headers?: HeadersInit
      }
    | {
        method: 'POST' | 'PUT' | 'PATCH'
        contentType:
          | 'application/x-www-form-urlencoded'
          | 'multipart/form-data'
          | 'application/json'
        body: Record<
          string,
          string | number | boolean | object | File | undefined
        >
        headers?: HeadersInit
      }
): Promise<Response> => {
  const headers: HeadersInit & { 'Content-Type'?: string } = {
    ...opts?.headers,
  }
  let body = undefined
  if (opts && 'contentType' in opts) {
    if (opts.contentType !== 'multipart/form-data') {
      headers['Content-Type'] = opts.contentType
    }
    switch (opts.contentType) {
      case 'application/json':
        body = JSON.stringify(opts.body)
        break
      case 'application/x-www-form-urlencoded':
        body = new URLSearchParams()
        for (const [k, v] of Object.entries(opts.body)) {
          if (v != null) {
            switch (typeof v) {
              case 'string':
                body.append(k, v)
                break
              case 'boolean':
                body.append(k, String(v))
                break
              case 'number':
                body.append(k, String(v))
                break
              case 'object':
                body.append(k, JSON.stringify(v))
                break
            }
          }
        }
        break
      case 'multipart/form-data':
        body = new FormData()
        for (const [k, v] of Object.entries(opts.body)) {
          if (v != null) {
            switch (typeof v) {
              case 'string':
                body.append(k, v)
                break
              case 'boolean':
                body.append(k, String(v))
                break
              case 'number':
                body.append(k, String(v))
                break
              case 'object':
                if (v instanceof File) {
                  body.append(k, v, v.name)
                } else {
                  body.append(k, JSON.stringify(v))
                }
                break
            }
          }
        }
        break
      case 'application/json':
        body = JSON.stringify(opts.body)
        break
    }
  }

  const res = await fetch(url, {
    method: opts?.method,
    headers: headers,
    body: body,
  })

  if (!res.ok) {
    const err: Record<string, any> = await createSuperFetchError({
      method: opts?.method ?? 'GET',
      url: url,
      requestBody: body,
      res: res,
    })
    console.error(err)
    throw err
  }
  const resBody = await res.json()
  return camelcaseKeys(resBody, { deep: true }) as Response
}

type SuperFetchError = {
  isSupeFetchError: true
  request: {
    method: string
    url: string
    body: string
  }
  response: {
    status: number
    body: string
  }
}

export const isSuperFetchError = (error: any): error is SuperFetchError => {
  return !!error.isSupeFetchError
}
