import React, { Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { cx } from '@linaria/core'

import { CloseIcon } from '../icons'

export const Modal: React.FC<{
  isOpen: boolean
  title: React.ReactNode
  body?: React.ReactNode
  actionButtons?: React.ReactNode
  px?: `px-${number}`
  setIsOpen?: (v: boolean) => void
  hideCloseButton?: boolean
}> = (props) => {
  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999]"
          onClose={() => props.setIsOpen && props.setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cx(
                    'w-full max-w-md transform overflow-hidden bg-white py-5 text-left align-middle shadow-xl transition-all',
                    props.px ? props.px : 'px-8'
                  )}
                >
                  <div className="text-right">
                    {props.setIsOpen != null && !props.hideCloseButton && (
                      <button
                        type="button"
                        onClick={() =>
                          props.setIsOpen && props.setIsOpen(false)
                        }
                        className="outline-none"
                      >
                        <CloseIcon className="fill-text w-[14px] h-[14px]" />
                      </button>
                    )}
                  </div>
                  <Dialog.Title as="h3" className="text-center font-bold mb-3">
                    {props.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="text-[13px] leading-[22px] text-gray-500 whitespace-pre-wrap break-words">
                      {props.body}
                    </div>
                  </div>
                  <div className="mt-4">{props.actionButtons}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
