import type {
  AdministratorAuthResponse,
  AdministratorResponse,
  AmazonIncentiveBalanceResponse,
  AutoChargeSuggestionResponse,
  BankAccountResponse,
  BankAccountsResponse,
  BankBranchesResponse,
  BanksResponse,
  ChargesResponse,
  ChatMessageResponse,
  ChatMessagesResponse,
  ChatSpaceEventsResponse,
  ChatSpaceMemberResponse,
  ChatSpaceResponse,
  ChatSpacesResponse,
  DinnerAttendeesResponse,
  DinnerCandidatesResponse,
  DinnerInvitationCodeResponse,
  DinnerMemberResponse,
  DinnerMemoResponse,
  DinnerOptionListResponse,
  DinnerParticipationResponse,
  DinnerResponse,
  DinnersResponse,
  GiftCategoriesResponse,
  GiftResponse,
  GiftedUsersResponse,
  ImageResponse,
  InAppPurchasedProductResponse,
  InAppPurchasedProductsResponse,
  IntroductionLetterResponse,
  IntroductionLettersResponse,
  OfficialDictionaryResponse,
  OwnedGiftsResponse,
  OwnedPointResponse,
  OwnedPointsResponse,
  PaymentCardResponse,
  PaymentCardsResponse,
  PointDealsResponse,
  PushSubscriptionListResponse,
  RelatedImagesResponse,
  RestaurantCategoriesResponse,
  RestaurantFollowingListResponse,
  RestaurantPhotoClipResponse,
  RestaurantPhotoClipsResponse,
  RestaurantRegionsResponse,
  RestaurantResponse,
  RestaurantsResponse,
  SaleGiftsResponse,
  SalePointsResponse,
  ServiceNoticeResponse,
  ServiceNoticesResponse,
  SuccessCountResponse,
  SuccessResponse,
  TabelogRestaurantSuggestionResponse,
  TweetReplyResponse,
  TweetResponse,
  TweetsResponse,
  UnreadBadgeListResponse,
  UserAuthResponse,
  UserContactResponse,
  UserDictionaryResponse,
  UserInvitationCodeResponse,
  UserMemoResponse,
  UserNotificationsResponse,
  UserRegionsResponse,
  UserResponse,
  UsersResponse,
  WithdrawalReceptionStatusResponse,
  WithdrawalRequestResponse,
  WithdrawalRequestsResponse,
} from '~/api-definition/response'

export type JsonValue =
  | string
  | number
  | boolean
  | null
  | JsonValue[]
  | { [key: string]: JsonValue }
export type FoodAuthType = 'none' | 'optional' | 'required'
export type FoodMutationHttpMethod = 'POST' | 'PUT' | 'DELETE'

export type _FoodGetApi = {
  '/static/banks': {
    response: BanksResponse
    params: {}
  }
  '/static/bank_branches': {
    response: BankBranchesResponse
    params: {
      bankId: string
    }
  }
  '/static/user_regions': {
    response: UserRegionsResponse
    params: {}
  }
  '/static/restaurant_regions': {
    response: RestaurantRegionsResponse
    params: {}
  }
  '/static/restaurant_categories': {
    response: RestaurantCategoriesResponse
    params: {}
  }
  '/static/sale_points': {
    response: SalePointsResponse
    params: {}
  }
  '/static/gift_categories': {
    response: GiftCategoriesResponse
    params: {}
  }
  '/static/gift': {
    response: GiftResponse
    params: {
      giftId: string
    }
  }
  '/static/sale_gifts': {
    response: SaleGiftsResponse
    params: {}
  }
  '/static/dinner_option_list': {
    response: DinnerOptionListResponse
    params: {}
  }
  '/user/me': {
    response: UserResponse
    params: {}
  }
  '/user/profile': {
    response: UserResponse
    params: {
      userId?: string
      userName?: string
      userInvitationCode?: string
    }
  }
  '/user/available_user_name': {
    response: SuccessResponse
    params: {
      userName: string
    }
  }
  '/user/invitation_code': {
    response: UserInvitationCodeResponse
    params: {}
  }
  '/user/push_subscription_list': {
    response: PushSubscriptionListResponse
    params: {}
  }
  '/user/invited_user': {
    response: UserResponse
    params: {
      userId?: string
      userName?: string
    }
  }
  '/user/invited_users': {
    response: UsersResponse
    params: {
      count?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/user/search_users': {
    response: UsersResponse
    params: {
      key: string
      count?: number
      offset?: number
    }
  }
  '/user/recommended_users': {
    response: UsersResponse
    params: {
      count?: number
    }
  }
  '/user/rookie_users': {
    response: UsersResponse
    params: {
      count?: number
    }
  }
  '/user/user_memo': {
    response: UserMemoResponse
    params: {
      userId?: string
      userName?: string
    }
  }
  '/user/unread_badge_list': {
    response: UnreadBadgeListResponse
    params: {}
  }
  '/user/payment_cards': {
    response: PaymentCardsResponse
    params: {}
  }
  '/user/bank_accounts': {
    response: BankAccountsResponse
    params: {}
  }
  '/user/owned_points': {
    response: OwnedPointsResponse
    params: {}
  }
  '/user/in_app_purchased_products': {
    response: InAppPurchasedProductsResponse
    params: {}
  }
  '/user/auto_charge_suggestion': {
    response: AutoChargeSuggestionResponse
    params: {
      requiredPointAmount: number
    }
  }
  '/user/charges': {
    response: ChargesResponse
    params: {
      count?: number
      offset?: number
    }
  }
  '/user/point_deals': {
    response: PointDealsResponse
    params: {
      count?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/user/withdrawal_reception_status': {
    response: WithdrawalReceptionStatusResponse
    params: {}
  }
  '/user/withdrawal_requests': {
    response: WithdrawalRequestsResponse
    params: {
      count?: number
      offset?: number
    }
  }
  '/user/owned_gifts': {
    response: OwnedGiftsResponse
    params: {
      userId?: string
    }
  }
  '/user/gifted_users': {
    response: GiftedUsersResponse
    params: {
      giftId: string
    }
  }
  '/user/user_notifications': {
    response: UserNotificationsResponse
    params: {
      count?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/user/following_users': {
    response: UsersResponse
    params: {
      userId: string
      count?: number
      offset?: number
    }
  }
  '/user/followed_users': {
    response: UsersResponse
    params: {
      userId: string
      count?: number
      offset?: number
    }
  }
  '/user/user_dictionary': {
    response: UserDictionaryResponse
    params: {}
  }
  '/user/official_dictionary': {
    response: OfficialDictionaryResponse
    params: {}
  }
  '/user/introduction_letter': {
    response: IntroductionLetterResponse
    params: {
      introductionLetterId?: string
      toUserId?: string
    }
  }
  '/user/introduction_letters': {
    response: IntroductionLettersResponse
    params: {
      userId?: string
      fromMe?: boolean
      count?: number
      offset?: number
      state?: string
    }
  }
  '/user/restaurant_following_list': {
    response: RestaurantFollowingListResponse
    params: {
      userId?: string
    }
  }
  '/user/restaurant_following_users': {
    response: UsersResponse
    params: {
      restaurantId: string
      followingType: string
      filter?: string
      count?: number
      offset?: number
    }
  }
  '/user/restaurant_photo_clips': {
    response: RestaurantPhotoClipsResponse
    params: {
      privacyType?: string
      count?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/user/dinners': {
    response: DinnersResponse
    params: {
      userId?: string
      attendedUserId?: string
      memberFilter?: string
      sortBy?: string
      passedScheduledAt?: boolean
      count?: number
      offset?: number
    }
  }
  '/user/dinner_attendees': {
    response: DinnerAttendeesResponse
    params: {}
  }
  '/restaurant/restaurant': {
    response: RestaurantResponse
    params: {
      restaurantId?: string
      referralRestaurantUrl?: string
    }
  }
  '/restaurant/search_restaurants': {
    response: RestaurantsResponse
    params: {
      key: string
      count?: number
      offset?: number
    }
  }
  '/restaurant/restaurant_photo_clip': {
    response: RestaurantPhotoClipResponse
    params: {
      restaurantPhotoClipId: string
    }
  }
  '/restaurant/restaurant_photo_clips': {
    response: RestaurantPhotoClipsResponse
    params: {
      restaurantId: string
      count?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/dinner/dinner': {
    response: DinnerResponse
    params: {
      dinnerId?: string
      dinnerInvitationCode?: string
    }
  }
  '/dinner/dinners': {
    response: DinnersResponse
    params: {
      memberFilter?: string
      sortBy?: string
      onlySeatsLeft?: boolean
      count?: number
      offset?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/dinner/dinner_participation': {
    response: DinnerParticipationResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/invitation_code': {
    response: DinnerInvitationCodeResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/dinner_memo': {
    response: DinnerMemoResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/dinner_candidates': {
    response: DinnerCandidatesResponse
    params: {
      dinnerId: string
    }
  }
  '/chat/chat_space': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId?: string
      pairUserId?: string
      dinnerId?: string
    }
  }
  '/chat/chat_spaces': {
    response: ChatSpacesResponse
    params: {
      count?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/chat/chat_space_me': {
    response: ChatSpaceMemberResponse
    params: {
      chatSpaceId: string
    }
  }
  '/chat/chat_space_events': {
    response: ChatSpaceEventsResponse
    params: {
      chatSpaceId: string
      count?: number
    }
  }
  '/chat/chat_messages': {
    response: ChatMessagesResponse
    params: {
      chatSpaceId: string
      count?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/tweet/tweet': {
    response: TweetResponse
    params: {
      tweetId: string
    }
  }
  '/tweet/tweets': {
    response: TweetsResponse
    params: {
      count?: number
      cursorNext?: string
      cursorBefore?: string
    }
  }
  '/tweet/reply': {
    response: TweetReplyResponse
    params: {
      tweetReplyId: string
    }
  }
  '/information/service_notice': {
    response: ServiceNoticeResponse
    params: {
      serviceNoticeId: string
    }
  }
  '/information/service_notices': {
    response: ServiceNoticesResponse
    params: {
      count?: number
      offset?: number
    }
  }
  '/admin/me': {
    response: AdministratorResponse
    params: {}
  }
  '/admin/users': {
    response: UsersResponse
    params: {
      filter?: string
      count?: number
      offset?: number
    }
  }
  '/admin/withdrawal_requests': {
    response: WithdrawalRequestsResponse
    params: {
      count?: number
      offset?: number
    }
  }
  '/admin/restaurants': {
    response: RestaurantsResponse
    params: {
      count?: number
      offset?: number
    }
  }
  '/admin/dinners': {
    response: DinnersResponse
    params: {
      count?: number
      offset?: number
    }
  }
  '/admin/service_notice': {
    response: ServiceNoticeResponse
    params: {
      serviceNoticeId: string
    }
  }
  '/admin/service_notices': {
    response: ServiceNoticesResponse
    params: {
      count?: number
      offset?: number
    }
  }
  '/admin/related_images': {
    response: RelatedImagesResponse
    params: {
      count?: number
      offset?: number
      relationType?: string
    }
  }
  '/admin/amazon_incentive_balance': {
    response: AmazonIncentiveBalanceResponse
    params: {}
  }
  '/admin/tabelog_restaurant_suggestion': {
    response: TabelogRestaurantSuggestionResponse
    params: {
      tabelogRestaurantId: number
    }
  }
}

export type _FoodMutationApi = {
  '/auth/openid_signin': {
    response: UserAuthResponse
    params: {
      openidType: string
      token?: string
      nonce?: string
      code?: string
      redirectUri?: string
      userInvitationCode?: string
      dinnerInvitationCode?: string
      countryCode?: string
    }
  }
  '/auth/signin': {
    response: UserAuthResponse
    params: {
      email: string
      password: string
    }
  }
  '/auth/signup': {
    response: SuccessResponse
    params: {
      email: string
      password: string
      clientType: string
    }
  }
  '/auth/signup_complete': {
    response: UserAuthResponse
    params: {
      key: string
    }
  }
  '/auth/change_email_complete': {
    response: SuccessResponse
    params: {
      key: string
    }
  }
  '/auth/reset_password': {
    response: SuccessResponse
    params: {
      email: string
      clientType: string
    }
  }
  '/auth/reset_password_complete': {
    response: SuccessResponse
    params: {
      password: string
      key: string
    }
  }
  '/user/renew_invitation_code': {
    response: UserInvitationCodeResponse
    params: {}
  }
  '/user/new_fcm_token': {
    response: SuccessResponse
    params: {
      clientType: string
      fcmToken: string
    }
  }
  '/user/delete_fcm_token': {
    response: SuccessResponse
    params: {
      clientType: string
      fcmToken: string
    }
  }
  '/user/update_push_badge': {
    response: SuccessResponse
    params: {
      badgeCount: number
    }
  }
  '/user/update_push_subscription_list': {
    response: PushSubscriptionListResponse
    params: {
      dinnerRecruitment?: string
      dinnerWishRestaurant?: string
      dinnerScheduleReminderHour?: string
      dinnerScheduleReminderDay?: string
      dinnerScheduleReminderWeek?: string
      dinnerOthers?: string
      chatMessage?: string
      tweet?: string
      followed?: string
      introductionLetter?: string
    }
  }
  '/user/update_profile': {
    response: UserResponse
    params: {
      userName?: string
      displayName?: string
      biography?: string
      agreedTerms?: boolean
      birthday?: string
      unsetBirthday?: boolean
      showAge?: boolean
      sex?: string
      showSex?: boolean
      userRegionId?: string
      unsetUserRegion?: boolean
      showRegion?: boolean
      personalMemo?: string
      unfavoriteFoods?: string
      allergicFoods?: string
      alcoholAmountType?: string
      favoriteAlcohol?: string
    }
  }
  '/user/update_profile_icon': {
    response: UserResponse
    params: {
      imageFile: File
    }
  }
  '/user/update_social_id': {
    response: UserResponse
    params: {
      instagramUserId?: string
      usingInstagramThreads?: boolean
      facebookUserId?: string
      twitterUserId?: string
      tabelogUserId?: string
      youtubeChannelId?: string
      tiktokUserId?: string
      litlinkUserId?: string
    }
  }
  '/user/update_user_memo': {
    response: UserMemoResponse
    params: {
      userId: string
      text?: string
      unsetText?: boolean
    }
  }
  '/user/change_email': {
    response: SuccessResponse
    params: {
      email: string
      password: string
    }
  }
  '/user/change_password': {
    response: SuccessResponse
    params: {
      password: string
      newPassword: string
    }
  }
  '/user/mark_unread_badge': {
    response: UnreadBadgeListResponse
    params: {
      unreadBadgeType: string
    }
  }
  '/user/new_payment_card': {
    response: PaymentCardResponse
    params: {
      stripeCardToken: string
      asDefault?: boolean
    }
  }
  '/user/default_payment_card': {
    response: PaymentCardResponse
    params: {
      paymentCardId: string
    }
  }
  '/user/delete_payment_card': {
    response: SuccessResponse
    params: {
      paymentCardId: string
    }
  }
  '/user/new_bank_account': {
    response: BankAccountResponse
    params: {
      bankId: string
      bankBranchId: string
      accountType: string
      accountNumber: string
      accountHolder: string
    }
  }
  '/user/default_bank_account': {
    response: BankAccountResponse
    params: {
      bankAccountId: string
    }
  }
  '/user/delete_bank_account': {
    response: SuccessResponse
    params: {
      bankAccountId: string
    }
  }
  '/user/new_owned_point': {
    response: OwnedPointResponse
    params: {
      paymentCardId: string
      pointAmount: number
      jpyPriceAmount: number
    }
  }
  '/user/new_in_app_purchased_product': {
    response: InAppPurchasedProductResponse
    params: {
      clientType: string
      receiptData?: string
      packageName?: string
      productId?: string
      purchaseToken?: string
    }
  }
  '/user/exchange_amazon_gift': {
    response: SuccessResponse
    params: {
      pointAmount: number
      email: string
    }
  }
  '/user/exchange_campaign_goods': {
    response: SuccessResponse
    params: {
      campaignGoodsType: string
      postalCode?: string
      address?: string
      name?: string
      phoneNumber?: string
    }
  }
  '/user/new_withdrawal_request': {
    response: WithdrawalRequestResponse
    params: {
      bankAccountId: string
      pointAmount: number
      feePointAmount: number
    }
  }
  '/user/follow_user': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/user/unfollow_user': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/user/delete_followed_user': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/user/mute_user': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/user/unmute_user': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/user/new_user_dictionary_term': {
    response: UserDictionaryResponse
    params: {
      text: string
    }
  }
  '/user/update_user_dictionary_term': {
    response: UserDictionaryResponse
    params: {
      userDictionaryTermId: string
      text: string
    }
  }
  '/user/delete_user_dictionary_term': {
    response: UserDictionaryResponse
    params: {
      userDictionaryTermId: string
    }
  }
  '/user/delete_official_dictionary_term': {
    response: OfficialDictionaryResponse
    params: {
      officialDictionaryTermId: string
    }
  }
  '/user/new_introduction_letter': {
    response: IntroductionLetterResponse
    params: {
      userId: string
      title: string
      bodyText?: string
    }
  }
  '/user/update_introduction_letter': {
    response: IntroductionLetterResponse
    params: {
      introductionLetterId: string
      title?: string
      bodyText?: string
    }
  }
  '/user/approve_introduction_letter': {
    response: IntroductionLetterResponse
    params: {
      introductionLetterId: string
    }
  }
  '/user/cancel_introduction_letter': {
    response: IntroductionLetterResponse
    params: {
      introductionLetterId: string
    }
  }
  '/user/delete_introduction_letter': {
    response: SuccessResponse
    params: {
      introductionLetterId: string
    }
  }
  '/user/new_following_wish_restaurant': {
    response: RestaurantFollowingListResponse
    params: {
      restaurantId: string
    }
  }
  '/user/update_following_wish_restaurant': {
    response: RestaurantFollowingListResponse
    params: {
      restaurantId: string
      position: number
    }
  }
  '/user/delete_following_wish_restaurant': {
    response: SuccessResponse
    params: {
      restaurantId: string
    }
  }
  '/user/request_restaurant_permission': {
    response: SuccessResponse
    params: {
      restaurantId: string
    }
  }
  '/user/new_user_contact': {
    response: UserContactResponse
    params: {
      userContactType: string
      bodyText: string
    }
  }
  '/user/signout': {
    response: SuccessResponse
    params: {}
  }
  '/user/deactivate': {
    response: SuccessResponse
    params: {}
  }
  '/restaurant/new_restaurant_photo_clip_image': {
    response: ImageResponse
    params: {
      restaurantId: string
      imageFile: File
    }
  }
  '/restaurant/new_restaurant_photo_clip': {
    response: RestaurantPhotoClipResponse
    params: {
      restaurantId: string
      privacyType: string
      imageIds: JsonValue
      visitedDate?: string
    }
  }
  '/restaurant/update_restaurant_photo_clip': {
    response: RestaurantPhotoClipResponse
    params: {
      restaurantPhotoClipId: string
      privacyType?: string
      imageIds?: JsonValue
      visitedDate?: string
    }
  }
  '/restaurant/delete_restaurant_photo_clip': {
    response: SuccessResponse
    params: {
      restaurantPhotoClipId: string
    }
  }
  '/dinner/new_dinner_thumbnail_image': {
    response: ImageResponse
    params: {
      imageFile: File
    }
  }
  '/dinner/new_dinner': {
    response: DinnerResponse
    params: {
      dinnerName: string
      scheduledAt: number
      reservationNumber: number
      recruitmentNumber: number
      privacyType: string
      minBudgetAmount?: number
      maxBudgetAmount?: number
      fixedBudgetAmount?: number
      thumbnailImageIds?: JsonValue
      restaurantId?: string
      restaurantName?: string
      restaurantAddress?: string
      restaurantUrl?: string
      favoriteAlcoholType: string
      alcoholAmountType: string
      foodAmountType: string
      sexType: string
      paymentType: string
      minAge?: number
      maxAge?: number
      note?: string
      approvementScheduledAt?: number
      cancellationPolicies?: JsonValue
      invitedCandidates?: JsonValue
      memoText?: string
    }
  }
  '/dinner/update_dinner': {
    response: DinnerResponse
    params: {
      dinnerId: string
      dinnerName?: string
      scheduledAt?: number
      reservationNumber?: number
      recruitmentNumber?: number
      privacyType?: string
      minBudgetAmount?: number
      maxBudgetAmount?: number
      fixedBudgetAmount?: number
      thumbnailImageIds?: JsonValue
      restaurantId?: string
      restaurantName?: string
      restaurantAddress?: string
      restaurantUrl?: string
      favoriteAlcoholType?: string
      alcoholAmountType?: string
      foodAmountType?: string
      sexType?: string
      paymentType?: string
      minAge?: number
      maxAge?: number
      note?: string
      approvementScheduledAt?: number
    }
  }
  '/dinner/delete_dinner': {
    response: SuccessResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/report_dinner': {
    response: SuccessResponse
    params: {
      dinnerId: string
      text: string
    }
  }
  '/dinner/renew_invitation_code': {
    response: DinnerInvitationCodeResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/update_dinner_memo': {
    response: DinnerMemoResponse
    params: {
      dinnerId: string
      text?: string
      unsetText?: boolean
    }
  }
  '/dinner/apply_dinner': {
    response: SuccessResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/cancel_dinner': {
    response: SuccessResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/approve_dinner_invitation': {
    response: DinnerResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/reject_dinner_invitation': {
    response: SuccessResponse
    params: {
      dinnerId: string
    }
  }
  '/dinner/update_dinner_member': {
    response: DinnerMemberResponse
    params: {
      dinnerId: string
      userId: string
      memberType: string
    }
  }
  '/dinner/delete_dinner_member': {
    response: SuccessResponse
    params: {
      dinnerId: string
      userId: string
    }
  }
  '/dinner/new_dinner_candidates': {
    response: DinnerCandidatesResponse
    params: {
      dinnerId: string
      invitedCandidates: JsonValue
    }
  }
  '/dinner/approve_dinner_candidate': {
    response: SuccessResponse
    params: {
      dinnerId: string
      userId: string
    }
  }
  '/dinner/reject_dinner_candidate': {
    response: SuccessResponse
    params: {
      dinnerId: string
      userId: string
    }
  }
  '/dinner/reject_dinner_candidates': {
    response: SuccessResponse
    params: {
      dinnerId: string
      userIds: JsonValue
      message?: string
    }
  }
  '/dinner/cancel_dinner_candidate': {
    response: SuccessResponse
    params: {
      dinnerId: string
      userId: string
    }
  }
  '/dinner/mute_dinner_candidate': {
    response: SuccessResponse
    params: {
      dinnerId: string
      userId: string
    }
  }
  '/chat/new_chat_space': {
    response: ChatSpaceResponse
    params: {
      spaceType: string
      pairUserId?: string
      dmName?: string
      groupName?: string
      groupMembers?: JsonValue
      passedMessagesVisible?: boolean
    }
  }
  '/chat/update_chat_space': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId: string
      dmName?: string
      groupName?: string
    }
  }
  '/chat/update_chat_space_icon': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId: string
      imageFile: File
    }
  }
  '/chat/delete_chat_space': {
    response: SuccessResponse
    params: {
      chatSpaceId: string
    }
  }
  '/chat/leave_chat_space': {
    response: SuccessResponse
    params: {
      chatSpaceId: string
    }
  }
  '/chat/new_chat_space_members': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId: string
      groupMembers: JsonValue
    }
  }
  '/chat/request_chat_space_members': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId: string
      groupUserIds: JsonValue
    }
  }
  '/chat/approve_chat_space_members': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId: string
      groupUserIds: JsonValue
    }
  }
  '/chat/reject_chat_space_members': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId: string
      groupUserIds: JsonValue
    }
  }
  '/chat/update_chat_space_member': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId: string
      groupUserId: string
      permissionType: string
    }
  }
  '/chat/delete_chat_space_members': {
    response: ChatSpaceResponse
    params: {
      chatSpaceId: string
      groupUserIds: JsonValue
    }
  }
  '/chat/read_chat_space_event': {
    response: SuccessResponse
    params: {
      chatSpaceEventId: string
    }
  }
  '/chat/new_chat_message_text': {
    response: ChatMessageResponse
    params: {
      chatSpaceId: string
      message: string
    }
  }
  '/chat/new_chat_message_image': {
    response: ImageResponse
    params: {
      chatSpaceId: string
      imageFile: File
    }
  }
  '/chat/new_chat_message_images': {
    response: ChatMessageResponse
    params: {
      chatSpaceId: string
      imageIds: JsonValue
    }
  }
  '/chat/new_chat_message_gift': {
    response: ChatMessageResponse
    params: {
      chatSpaceId: string
      toUserId: string
      giftId: string
      giftPointAmount: number
      paymentCardId?: string
      autoChargePointAmount?: number
    }
  }
  '/chat/new_chat_message_iap_gift': {
    response: ChatMessageResponse
    params: {
      chatSpaceId: string
      toUserId: string
      inAppPurchasedProductId: string
    }
  }
  '/chat/read_chat_message': {
    response: SuccessResponse
    params: {
      chatMessageId: string
    }
  }
  '/chat/delete_chat_message': {
    response: SuccessResponse
    params: {
      chatMessageId: string
    }
  }
  '/chat/new_chat_message_reaction': {
    response: ChatMessageResponse
    params: {
      chatMessageId: string
      reactionType: string
    }
  }
  '/chat/delete_chat_message_reaction': {
    response: ChatMessageResponse
    params: {
      chatMessageId: string
      reactionType: string
    }
  }
  '/tweet/new_tweet_image': {
    response: ImageResponse
    params: {
      imageFile: File
    }
  }
  '/tweet/new_tweet': {
    response: TweetResponse
    params: {
      text: string
      imageIds?: JsonValue
    }
  }
  '/tweet/update_tweet': {
    response: TweetResponse
    params: {
      tweetId: string
      text?: string
      imageIds?: JsonValue
    }
  }
  '/tweet/delete_tweet': {
    response: SuccessResponse
    params: {
      tweetId: string
    }
  }
  '/tweet/report_tweet': {
    response: SuccessResponse
    params: {
      tweetId: string
      text: string
    }
  }
  '/tweet/new_tweet_gift': {
    response: TweetResponse
    params: {
      tweetId: string
      giftId: string
      giftPointAmount: number
      paymentCardId?: string
      autoChargePointAmount?: number
    }
  }
  '/tweet/new_tweet_iap_gift': {
    response: TweetResponse
    params: {
      tweetId: string
      inAppPurchasedProductId: string
    }
  }
  '/tweet/new_reply_image': {
    response: ImageResponse
    params: {
      imageFile: File
    }
  }
  '/tweet/new_reply': {
    response: TweetReplyResponse
    params: {
      tweetId?: string
      tweetReplyId?: string
      text: string
      imageIds?: JsonValue
    }
  }
  '/tweet/update_reply': {
    response: TweetReplyResponse
    params: {
      tweetReplyId: string
      text?: string
      imageIds?: JsonValue
    }
  }
  '/tweet/delete_reply': {
    response: SuccessResponse
    params: {
      tweetReplyId: string
    }
  }
  '/tweet/report_reply': {
    response: SuccessResponse
    params: {
      tweetReplyId: string
      text: string
    }
  }
  '/tweet/new_reply_gift': {
    response: TweetReplyResponse
    params: {
      tweetReplyId: string
      giftId: string
      giftPointAmount: number
      paymentCardId?: string
      autoChargePointAmount?: number
    }
  }
  '/tweet/new_reply_iap_gift': {
    response: TweetReplyResponse
    params: {
      tweetReplyId: string
      inAppPurchasedProductId: string
    }
  }
  '/admin/signin': {
    response: AdministratorAuthResponse
    params: {
      email: string
      password: string
    }
  }
  '/admin/change_password': {
    response: SuccessResponse
    params: {
      password: string
      newPassword: string
    }
  }
  '/admin/new_user': {
    response: UserResponse
    params: {
      email: string
      password: string
      displayName: string
      userName?: string
    }
  }
  '/admin/init_restaurant': {
    response: SuccessCountResponse
    params: {
      tabelogRestaurantId?: number
    }
  }
  '/admin/init_restaurants': {
    response: SuccessCountResponse
    params: {
      newCount?: number
      renewCount?: number
      renewOffset?: number
    }
  }
  '/admin/new_restaurant': {
    response: RestaurantResponse
    params: {
      tabelogRestaurantId?: number
      tabelogRestaurantUrl?: string
      restaurantName: string
      kanaName: string
      aliasName?: string
      address: string
      areaName?: string
      latitude?: string
      longitude?: string
      budgetLunch?: string
      budgetDinner?: string
      phoneNumber?: string
      businessHour?: string
      regularHoliday?: string
      transportation?: string
      payment?: string
      reservation?: string
      seat?: string
      privateRoom?: string
      parking?: string
      kids?: string
      smoking?: string
      remark?: string
      openedDate?: string
      officialUrl?: string
      twitterUrl?: string
      instagramUrl?: string
      facebookUrl?: string
      restaurantRegionId?: string
      restaurantCategoryIds?: JsonValue
    }
  }
  '/admin/update_restaurant': {
    response: RestaurantResponse
    params: {
      restaurantId: string
      restaurantName?: string
      kanaName?: string
      aliasName?: string
      address?: string
      areaName?: string
      latitude?: string
      longitude?: string
      budgetLunch?: string
      budgetDinner?: string
      phoneNumber?: string
      businessHour?: string
      regularHoliday?: string
      transportation?: string
      payment?: string
      reservation?: string
      seat?: string
      privateRoom?: string
      parking?: string
      kids?: string
      smoking?: string
      remark?: string
      openedDate?: string
      tabelogRestaurantUrl?: string
      officialUrl?: string
      twitterUrl?: string
      instagramUrl?: string
      facebookUrl?: string
      restaurantRegionId?: string
      restaurantCategoryIds?: JsonValue
    }
  }
  '/admin/new_restaurant_thumbnail_image': {
    response: RestaurantResponse
    params: {
      restaurantId: string
      imageFile: File
    }
  }
  '/admin/delete_restaurant_thumbnail_image': {
    response: RestaurantResponse
    params: {
      restaurantId: string
      imageId: string
    }
  }
  '/admin/new_restaurant_cover_image': {
    response: RestaurantResponse
    params: {
      restaurantId: string
      imageFile: File
    }
  }
  '/admin/delete_restaurant_cover_image': {
    response: RestaurantResponse
    params: {
      restaurantId: string
      imageId: string
    }
  }
  '/admin/new_service_notice': {
    response: ServiceNoticeResponse
    params: {
      title: string
      bodyText: string
      state: string
    }
  }
  '/admin/update_service_notice': {
    response: ServiceNoticeResponse
    params: {
      serviceNoticeId: string
      title?: string
      bodyText?: string
      state?: string
    }
  }
  '/admin/ban_image': {
    response: ImageResponse
    params: {
      imageId: string
    }
  }
  '/admin/activate_image': {
    response: ImageResponse
    params: {
      imageId: string
    }
  }
  '/admin/update_user_rank': {
    response: UserResponse
    params: {
      userId: string
      rank?: string
      showRankFrame?: boolean
    }
  }
  '/admin/update_user_warning_status': {
    response: UserResponse
    params: {
      userId: string
      warningStatusType: string
      reasons: JsonValue
    }
  }
  '/admin/delete_user_warning_status': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/admin/deactivate_user': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/admin/ban_user': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/admin/activate_user': {
    response: UserResponse
    params: {
      userId: string
    }
  }
  '/admin/give_campaign_point': {
    response: SuccessResponse
    params: {
      userId: string
      campaignTitle: string
      pointAmount: number
    }
  }
  '/admin/send_push_messages': {
    response: SuccessCountResponse
    params: {
      userId: string
      title?: string
      body?: string
      imageUrl?: string
    }
  }
  '/admin/init_static_data': {
    response: SuccessResponse
    params: {}
  }
  '/admin/update_search_index': {
    response: SuccessCountResponse
    params: {
      indexType: string
      searchIndexHost?: string
    }
  }
  '/webhook/store_line_messaging': {
    response: SuccessResponse
    params: {
      signature: string
      body: string
    }
  }
  '/webhook/scheduled_trigger': {
    response: SuccessResponse
    params: {
      scheduledTriggerType: string
    }
  }
}

export const foodMutationHttpMethods: Record<
  keyof _FoodMutationApi,
  FoodMutationHttpMethod
> = {
  '/auth/openid_signin': 'POST',
  '/auth/signin': 'POST',
  '/auth/signup': 'POST',
  '/auth/signup_complete': 'POST',
  '/auth/change_email_complete': 'PUT',
  '/auth/reset_password': 'PUT',
  '/auth/reset_password_complete': 'PUT',
  '/user/renew_invitation_code': 'POST',
  '/user/new_fcm_token': 'POST',
  '/user/delete_fcm_token': 'DELETE',
  '/user/update_push_badge': 'PUT',
  '/user/update_push_subscription_list': 'PUT',
  '/user/update_profile': 'PUT',
  '/user/update_profile_icon': 'PUT',
  '/user/update_social_id': 'PUT',
  '/user/update_user_memo': 'PUT',
  '/user/change_email': 'PUT',
  '/user/change_password': 'PUT',
  '/user/mark_unread_badge': 'PUT',
  '/user/new_payment_card': 'POST',
  '/user/default_payment_card': 'PUT',
  '/user/delete_payment_card': 'DELETE',
  '/user/new_bank_account': 'POST',
  '/user/default_bank_account': 'PUT',
  '/user/delete_bank_account': 'DELETE',
  '/user/new_owned_point': 'POST',
  '/user/new_in_app_purchased_product': 'POST',
  '/user/exchange_amazon_gift': 'POST',
  '/user/exchange_campaign_goods': 'POST',
  '/user/new_withdrawal_request': 'POST',
  '/user/follow_user': 'POST',
  '/user/unfollow_user': 'DELETE',
  '/user/delete_followed_user': 'DELETE',
  '/user/mute_user': 'PUT',
  '/user/unmute_user': 'PUT',
  '/user/new_user_dictionary_term': 'POST',
  '/user/update_user_dictionary_term': 'PUT',
  '/user/delete_user_dictionary_term': 'DELETE',
  '/user/delete_official_dictionary_term': 'DELETE',
  '/user/new_introduction_letter': 'POST',
  '/user/update_introduction_letter': 'PUT',
  '/user/approve_introduction_letter': 'PUT',
  '/user/cancel_introduction_letter': 'PUT',
  '/user/delete_introduction_letter': 'DELETE',
  '/user/new_following_wish_restaurant': 'POST',
  '/user/update_following_wish_restaurant': 'PUT',
  '/user/delete_following_wish_restaurant': 'DELETE',
  '/user/request_restaurant_permission': 'POST',
  '/user/new_user_contact': 'POST',
  '/user/signout': 'DELETE',
  '/user/deactivate': 'DELETE',
  '/restaurant/new_restaurant_photo_clip_image': 'POST',
  '/restaurant/new_restaurant_photo_clip': 'POST',
  '/restaurant/update_restaurant_photo_clip': 'PUT',
  '/restaurant/delete_restaurant_photo_clip': 'DELETE',
  '/dinner/new_dinner_thumbnail_image': 'POST',
  '/dinner/new_dinner': 'POST',
  '/dinner/update_dinner': 'PUT',
  '/dinner/delete_dinner': 'DELETE',
  '/dinner/report_dinner': 'POST',
  '/dinner/renew_invitation_code': 'POST',
  '/dinner/update_dinner_memo': 'PUT',
  '/dinner/apply_dinner': 'POST',
  '/dinner/cancel_dinner': 'DELETE',
  '/dinner/approve_dinner_invitation': 'PUT',
  '/dinner/reject_dinner_invitation': 'DELETE',
  '/dinner/update_dinner_member': 'PUT',
  '/dinner/delete_dinner_member': 'DELETE',
  '/dinner/new_dinner_candidates': 'POST',
  '/dinner/approve_dinner_candidate': 'PUT',
  '/dinner/reject_dinner_candidate': 'DELETE',
  '/dinner/reject_dinner_candidates': 'DELETE',
  '/dinner/cancel_dinner_candidate': 'DELETE',
  '/dinner/mute_dinner_candidate': 'PUT',
  '/chat/new_chat_space': 'POST',
  '/chat/update_chat_space': 'PUT',
  '/chat/update_chat_space_icon': 'PUT',
  '/chat/delete_chat_space': 'DELETE',
  '/chat/leave_chat_space': 'DELETE',
  '/chat/new_chat_space_members': 'POST',
  '/chat/request_chat_space_members': 'POST',
  '/chat/approve_chat_space_members': 'PUT',
  '/chat/reject_chat_space_members': 'DELETE',
  '/chat/update_chat_space_member': 'PUT',
  '/chat/delete_chat_space_members': 'DELETE',
  '/chat/read_chat_space_event': 'PUT',
  '/chat/new_chat_message_text': 'POST',
  '/chat/new_chat_message_image': 'POST',
  '/chat/new_chat_message_images': 'POST',
  '/chat/new_chat_message_gift': 'POST',
  '/chat/new_chat_message_iap_gift': 'POST',
  '/chat/read_chat_message': 'PUT',
  '/chat/delete_chat_message': 'DELETE',
  '/chat/new_chat_message_reaction': 'POST',
  '/chat/delete_chat_message_reaction': 'DELETE',
  '/tweet/new_tweet_image': 'POST',
  '/tweet/new_tweet': 'POST',
  '/tweet/update_tweet': 'PUT',
  '/tweet/delete_tweet': 'DELETE',
  '/tweet/report_tweet': 'POST',
  '/tweet/new_tweet_gift': 'POST',
  '/tweet/new_tweet_iap_gift': 'POST',
  '/tweet/new_reply_image': 'POST',
  '/tweet/new_reply': 'POST',
  '/tweet/update_reply': 'PUT',
  '/tweet/delete_reply': 'DELETE',
  '/tweet/report_reply': 'POST',
  '/tweet/new_reply_gift': 'POST',
  '/tweet/new_reply_iap_gift': 'POST',
  '/admin/signin': 'POST',
  '/admin/change_password': 'PUT',
  '/admin/new_user': 'POST',
  '/admin/init_restaurant': 'POST',
  '/admin/init_restaurants': 'POST',
  '/admin/new_restaurant': 'POST',
  '/admin/update_restaurant': 'PUT',
  '/admin/new_restaurant_thumbnail_image': 'POST',
  '/admin/delete_restaurant_thumbnail_image': 'DELETE',
  '/admin/new_restaurant_cover_image': 'POST',
  '/admin/delete_restaurant_cover_image': 'DELETE',
  '/admin/new_service_notice': 'POST',
  '/admin/update_service_notice': 'PUT',
  '/admin/ban_image': 'DELETE',
  '/admin/activate_image': 'PUT',
  '/admin/update_user_rank': 'PUT',
  '/admin/update_user_warning_status': 'PUT',
  '/admin/delete_user_warning_status': 'DELETE',
  '/admin/deactivate_user': 'DELETE',
  '/admin/ban_user': 'DELETE',
  '/admin/activate_user': 'PUT',
  '/admin/give_campaign_point': 'POST',
  '/admin/send_push_messages': 'POST',
  '/admin/init_static_data': 'POST',
  '/admin/update_search_index': 'POST',
  '/webhook/store_line_messaging': 'POST',
  '/webhook/scheduled_trigger': 'POST',
}

export const foodApiAuthTypes: Record<
  keyof _FoodGetApi | keyof _FoodMutationApi,
  FoodAuthType
> = {
  '/static/banks': 'none',
  '/static/bank_branches': 'none',
  '/static/user_regions': 'none',
  '/static/restaurant_regions': 'none',
  '/static/restaurant_categories': 'none',
  '/static/sale_points': 'none',
  '/static/gift_categories': 'none',
  '/static/gift': 'none',
  '/static/sale_gifts': 'none',
  '/static/dinner_option_list': 'none',
  '/auth/openid_signin': 'none',
  '/auth/signin': 'none',
  '/auth/signup': 'none',
  '/auth/signup_complete': 'none',
  '/auth/change_email_complete': 'none',
  '/auth/reset_password': 'none',
  '/auth/reset_password_complete': 'none',
  '/user/me': 'required',
  '/user/profile': 'optional',
  '/user/available_user_name': 'none',
  '/user/invitation_code': 'required',
  '/user/renew_invitation_code': 'required',
  '/user/new_fcm_token': 'required',
  '/user/delete_fcm_token': 'required',
  '/user/update_push_badge': 'required',
  '/user/push_subscription_list': 'required',
  '/user/update_push_subscription_list': 'required',
  '/user/invited_user': 'optional',
  '/user/invited_users': 'required',
  '/user/search_users': 'optional',
  '/user/recommended_users': 'required',
  '/user/rookie_users': 'required',
  '/user/update_profile': 'required',
  '/user/update_profile_icon': 'required',
  '/user/update_social_id': 'required',
  '/user/user_memo': 'required',
  '/user/update_user_memo': 'required',
  '/user/change_email': 'required',
  '/user/change_password': 'required',
  '/user/unread_badge_list': 'required',
  '/user/mark_unread_badge': 'required',
  '/user/payment_cards': 'required',
  '/user/new_payment_card': 'required',
  '/user/default_payment_card': 'required',
  '/user/delete_payment_card': 'required',
  '/user/bank_accounts': 'required',
  '/user/new_bank_account': 'required',
  '/user/default_bank_account': 'required',
  '/user/delete_bank_account': 'required',
  '/user/owned_points': 'required',
  '/user/new_owned_point': 'required',
  '/user/in_app_purchased_products': 'required',
  '/user/new_in_app_purchased_product': 'required',
  '/user/auto_charge_suggestion': 'required',
  '/user/charges': 'required',
  '/user/point_deals': 'required',
  '/user/exchange_amazon_gift': 'required',
  '/user/exchange_campaign_goods': 'required',
  '/user/withdrawal_reception_status': 'required',
  '/user/withdrawal_requests': 'required',
  '/user/new_withdrawal_request': 'required',
  '/user/owned_gifts': 'optional',
  '/user/gifted_users': 'required',
  '/user/user_notifications': 'required',
  '/user/following_users': 'optional',
  '/user/followed_users': 'optional',
  '/user/follow_user': 'required',
  '/user/unfollow_user': 'required',
  '/user/delete_followed_user': 'required',
  '/user/mute_user': 'required',
  '/user/unmute_user': 'required',
  '/user/user_dictionary': 'required',
  '/user/new_user_dictionary_term': 'required',
  '/user/update_user_dictionary_term': 'required',
  '/user/delete_user_dictionary_term': 'required',
  '/user/official_dictionary': 'required',
  '/user/delete_official_dictionary_term': 'required',
  '/user/introduction_letter': 'required',
  '/user/introduction_letters': 'required',
  '/user/new_introduction_letter': 'required',
  '/user/update_introduction_letter': 'required',
  '/user/approve_introduction_letter': 'required',
  '/user/cancel_introduction_letter': 'required',
  '/user/delete_introduction_letter': 'required',
  '/user/restaurant_following_list': 'optional',
  '/user/new_following_wish_restaurant': 'required',
  '/user/update_following_wish_restaurant': 'required',
  '/user/delete_following_wish_restaurant': 'required',
  '/user/restaurant_following_users': 'optional',
  '/user/restaurant_photo_clips': 'required',
  '/user/request_restaurant_permission': 'required',
  '/user/dinners': 'required',
  '/user/dinner_attendees': 'required',
  '/user/new_user_contact': 'required',
  '/user/signout': 'required',
  '/user/deactivate': 'required',
  '/restaurant/restaurant': 'none',
  '/restaurant/search_restaurants': 'none',
  '/restaurant/restaurant_photo_clip': 'optional',
  '/restaurant/restaurant_photo_clips': 'optional',
  '/restaurant/new_restaurant_photo_clip_image': 'required',
  '/restaurant/new_restaurant_photo_clip': 'required',
  '/restaurant/update_restaurant_photo_clip': 'required',
  '/restaurant/delete_restaurant_photo_clip': 'required',
  '/dinner/dinner': 'optional',
  '/dinner/dinners': 'optional',
  '/dinner/new_dinner_thumbnail_image': 'required',
  '/dinner/new_dinner': 'required',
  '/dinner/update_dinner': 'required',
  '/dinner/delete_dinner': 'required',
  '/dinner/report_dinner': 'required',
  '/dinner/dinner_participation': 'required',
  '/dinner/invitation_code': 'required',
  '/dinner/renew_invitation_code': 'required',
  '/dinner/dinner_memo': 'required',
  '/dinner/update_dinner_memo': 'required',
  '/dinner/apply_dinner': 'required',
  '/dinner/cancel_dinner': 'required',
  '/dinner/approve_dinner_invitation': 'required',
  '/dinner/reject_dinner_invitation': 'required',
  '/dinner/update_dinner_member': 'required',
  '/dinner/delete_dinner_member': 'required',
  '/dinner/dinner_candidates': 'required',
  '/dinner/new_dinner_candidates': 'required',
  '/dinner/approve_dinner_candidate': 'required',
  '/dinner/reject_dinner_candidate': 'required',
  '/dinner/reject_dinner_candidates': 'required',
  '/dinner/cancel_dinner_candidate': 'required',
  '/dinner/mute_dinner_candidate': 'required',
  '/chat/chat_space': 'required',
  '/chat/chat_spaces': 'required',
  '/chat/new_chat_space': 'required',
  '/chat/update_chat_space': 'required',
  '/chat/update_chat_space_icon': 'required',
  '/chat/delete_chat_space': 'required',
  '/chat/chat_space_me': 'required',
  '/chat/leave_chat_space': 'required',
  '/chat/new_chat_space_members': 'required',
  '/chat/request_chat_space_members': 'required',
  '/chat/approve_chat_space_members': 'required',
  '/chat/reject_chat_space_members': 'required',
  '/chat/update_chat_space_member': 'required',
  '/chat/delete_chat_space_members': 'required',
  '/chat/chat_space_events': 'required',
  '/chat/read_chat_space_event': 'required',
  '/chat/chat_messages': 'required',
  '/chat/new_chat_message_text': 'required',
  '/chat/new_chat_message_image': 'required',
  '/chat/new_chat_message_images': 'required',
  '/chat/new_chat_message_gift': 'required',
  '/chat/new_chat_message_iap_gift': 'required',
  '/chat/read_chat_message': 'required',
  '/chat/delete_chat_message': 'required',
  '/chat/new_chat_message_reaction': 'required',
  '/chat/delete_chat_message_reaction': 'required',
  '/tweet/tweet': 'none',
  '/tweet/tweets': 'none',
  '/tweet/new_tweet_image': 'required',
  '/tweet/new_tweet': 'required',
  '/tweet/update_tweet': 'required',
  '/tweet/delete_tweet': 'required',
  '/tweet/report_tweet': 'required',
  '/tweet/new_tweet_gift': 'required',
  '/tweet/new_tweet_iap_gift': 'required',
  '/tweet/reply': 'none',
  '/tweet/new_reply_image': 'required',
  '/tweet/new_reply': 'required',
  '/tweet/update_reply': 'required',
  '/tweet/delete_reply': 'required',
  '/tweet/report_reply': 'required',
  '/tweet/new_reply_gift': 'required',
  '/tweet/new_reply_iap_gift': 'required',
  '/information/service_notice': 'none',
  '/information/service_notices': 'none',
  '/admin/signin': 'none',
  '/admin/me': 'required',
  '/admin/change_password': 'required',
  '/admin/new_user': 'required',
  '/admin/users': 'required',
  '/admin/withdrawal_requests': 'required',
  '/admin/restaurants': 'required',
  '/admin/init_restaurant': 'required',
  '/admin/init_restaurants': 'required',
  '/admin/new_restaurant': 'required',
  '/admin/update_restaurant': 'required',
  '/admin/new_restaurant_thumbnail_image': 'required',
  '/admin/delete_restaurant_thumbnail_image': 'required',
  '/admin/new_restaurant_cover_image': 'required',
  '/admin/delete_restaurant_cover_image': 'required',
  '/admin/dinners': 'required',
  '/admin/service_notice': 'required',
  '/admin/service_notices': 'required',
  '/admin/new_service_notice': 'required',
  '/admin/update_service_notice': 'required',
  '/admin/related_images': 'required',
  '/admin/ban_image': 'required',
  '/admin/activate_image': 'required',
  '/admin/update_user_rank': 'required',
  '/admin/update_user_warning_status': 'required',
  '/admin/delete_user_warning_status': 'required',
  '/admin/deactivate_user': 'required',
  '/admin/ban_user': 'required',
  '/admin/activate_user': 'required',
  '/admin/amazon_incentive_balance': 'required',
  '/admin/tabelog_restaurant_suggestion': 'required',
  '/admin/give_campaign_point': 'required',
  '/admin/send_push_messages': 'required',
  '/admin/init_static_data': 'required',
  '/admin/update_search_index': 'required',
  '/webhook/store_line_messaging': 'none',
  '/webhook/scheduled_trigger': 'none',
}
