import { ChatSpacePermissionType } from '~/api-definition/enumTypes'
import {
  ChatMessageResponse,
  ChatMessagesResponse,
  ChatSpaceResponse,
  ChatSpacesResponse,
  ImageResponse,
  SuccessResponse,
} from '~/api-definition/response'
import { config } from '~/config'

import { Paging } from '.'
import { superFetch } from '..'
import { userGetProfile } from './user'

export const chatGetChatSpacesFetcher =
  (accessToken: string) => (args: { searchParams?: {} }) => {
    const cfg = {
      url: (paging: Paging) =>
        config.api.url('/chat/chat_spaces', {
          searchParams: {
            ...args?.searchParams,
            ...paging,
          },
        }),
      fetcher: (paging: Paging) =>
        superFetch<ChatSpacesResponse>(cfg.url(paging), {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }),
    }
    return cfg
  }

export const chatGetChatSpaceFetcher =
  (accessToken: string) =>
  (args: {
    searchParams: { chat_space_id: string } | { pair_user_id: string }
  }) => {
    const url = config.api.url('/chat/chat_space', {
      searchParams: args.searchParams,
    })

    return {
      url,
      fetcher: () =>
        superFetch<ChatSpaceResponse>(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }),
    }
  }

export const chatNewChatSpace = (args: {
  accessToken: string
  body:
    | {
        space_type: 'direct_message'
        pair_user_id: string
        dm_name?: string
      }
    | {
        space_type: 'group'
        group_name: string
        group_members: {
          user_id: string
          permission_type: ChatSpacePermissionType
        }[]
        passed_messages_visible: boolean
      }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<ChatSpaceResponse>(config.api.url('/chat/new_chat_space'), {
    method: 'POST',
    contentType: 'application/x-www-form-urlencoded',
    headers,
    body: args.body,
  })
}

export const chatNewChatSpaceMembers = (args: {
  accessToken: string
  body: {
    chat_space_id: string
    group_members: {
      user_id: string
      permission_type: ChatSpacePermissionType
    }[]
  }
}) => {
  return superFetch<ChatSpaceResponse>(
    config.api.url('/chat/new_chat_space_members'),
    {
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      headers: {
        Authorization: `Bearer ${args.accessToken}`,
      },
      body: args.body,
    }
  )
}

export const chatGetChatMessagesFetcher =
  (accessToken: string) =>
  (args: {
    searchParams: {
      chat_space_id: string
    }
  }) => {
    const cfg = {
      url: (paging: Paging) => {
        return config.api.url('/chat/chat_messages', {
          searchParams: {
            ...args.searchParams,
            ...paging,
          },
        })
      },
      fetcher: (paging: Paging) => {
        return superFetch<ChatMessagesResponse>(cfg.url(paging), {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      },
    }
    return cfg
  }

export const chatReadChatMessage = (args: {
  accessToken: string
  body: {
    chat_message_id: string
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<ChatMessageResponse>(
    config.api.url('/chat/read_chat_message'),
    {
      method: 'PUT',
      contentType: 'application/x-www-form-urlencoded',
      headers,
      body: args.body,
    }
  )
}

export const chatNewChatMessageText = (args: {
  accessToken: string
  body: {
    chat_space_id: string
    message: string
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<ChatMessageResponse>(
    config.api.url('/chat/new_chat_message_text'),
    {
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      headers,
      body: args.body,
    }
  )
}

export const chatNewChatMessageImage = (args: {
  accessToken: string
  body: {
    chat_space_id: string
    image_file: File
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<ImageResponse>(
    config.api.url('/chat/new_chat_message_image'),
    {
      method: 'POST',
      contentType: 'multipart/form-data',
      headers,
      body: args.body,
    }
  )
}

export const chatNewChatMessageImages = (args: {
  accessToken: string
  body: {
    chat_space_id: string
    image_ids: string[]
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<ChatMessageResponse>(
    config.api.url('/chat/new_chat_message_images'),
    {
      method: 'POST',
      contentType: 'multipart/form-data',
      headers,
      body: args.body,
    }
  )
}

export const chatNewChatMessageGift = (args: {
  accessToken: string
  body: {
    chat_space_id: string
    to_user_id: string
    gift_id: string
    gift_point_amount: number
    payment_card_id?: string
    auto_charge_point_amount?: number
  }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<ChatMessageResponse>(
    config.api.url('/chat/new_chat_message_gift'),
    {
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      headers,
      body: args.body,
    }
  )
}

export const chatUpdateChatSpace = (args: {
  accessToken: string
  body:
    | {
        chat_space_id: string
        group_name: string
      }
    | {
        chat_space_id: string
        dm_name: string
      }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<ChatSpaceResponse>(
    config.api.url('/chat/update_chat_space'),
    {
      method: 'PUT',
      contentType: 'application/x-www-form-urlencoded',
      headers,
      body: args.body,
    }
  )
}

export const chatDeleteChatSpace = (args: {
  accessToken: string
  searchParams: {
    chat_space_id: string
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<SuccessResponse>(
    config.api.url('/chat/delete_chat_space', {
      searchParams: args.searchParams,
    }),
    {
      method: 'DELETE',
      headers,
    }
  )
}

export const chatUpdateChatSpaceIcon = (args: {
  accessToken: string
  body: {
    chat_space_id: string
    image_file: File
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<ChatSpaceResponse>(
    config.api.url('/chat/update_chat_space_icon'),
    {
      method: 'PUT',
      contentType: 'multipart/form-data',
      headers,
      body: args.body,
    }
  )
}

export const chatUpdateChatSpaceMember = (args: {
  accessToken: string
  body: {
    chat_space_id: string
    group_user_id: string
    permission_type: ChatSpacePermissionType
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<ChatSpaceResponse>(
    config.api.url('/chat/update_chat_space_member'),
    {
      method: 'PUT',
      contentType: 'application/x-www-form-urlencoded',
      headers,
      body: args.body,
    }
  )
}

export const chatDeleteChatSpaceMembers = (args: {
  accessToken: string
  searchParams: {
    chat_space_id: string
    group_user_ids: string[]
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<SuccessResponse>(
    config.api.url('/chat/delete_chat_space_members', {
      searchParams: args.searchParams,
    }),
    {
      method: 'DELETE',
      headers,
    }
  )
}

// Composed

export const chatGetChatSpace = async (args: {
  accessToken: string
  pairUserId: string
}) => {
  return chatGetChatSpaceFetcher(args.accessToken)({
    searchParams: {
      pair_user_id: args.pairUserId,
    },
  }).fetcher()
}

export const chatGetOrCreateChatSpace = async (args: {
  accessToken: string
  pairUserId: string
}) => {
  const res = await userGetProfile({
    accessToken: args.accessToken,
    searchParams: {
      user_id: args.pairUserId,
    },
  })

  return res.user.haveDmChatSpace
    ? chatGetChatSpace({
        accessToken: args.accessToken,
        pairUserId: args.pairUserId,
      })
    : chatNewChatSpace({
        accessToken: args.accessToken,
        body: {
          space_type: 'direct_message',
          pair_user_id: args.pairUserId,
        },
      })
}
