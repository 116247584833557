import { ComponentProps } from 'react'

export const Label: React.FC<{
  label?: React.ReactNode
  labelProps?: ComponentProps<'label'>
  required?: boolean
}> = (props) => (
  <label className="text-sm font-bold block mb-2" {...props.labelProps}>
    {props.label}
    {props.required && <span className="text-[#AD3F4C] text-base">*</span>}
  </label>
)
