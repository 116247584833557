import { useCallback } from 'react'

import { useErrorBoundary } from 'react-error-boundary'
import snakecaseKeys from 'snakecase-keys'
import useSWR, { SWRResponse } from 'swr'
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite'
import invariant from 'tiny-invariant'

import { config } from '~/config'
import { useAccessToken, useSession } from '~/provider/SessionProvider'

import { isSuperFetchError, superFetch } from '..'
import {
  FoodAuthType,
  foodApiAuthTypes,
  foodMutationHttpMethods,
} from './api-definition'
import { FoodGetApi, FoodMutationApi } from './api-definition-overrides'
import { notNullish } from './null'

export type PagingOffset = { count?: number; offset?: number }
export type WithPagingOffset<T> = T & PagingOffset

export type PagingCursor = {
  cursor_next?: string
  cursor_before?: string
}
export type WithPagingCursor<T> = T & PagingCursor

export type Paging = PagingOffset | PagingCursor
export type WithPaging<T> = WithPagingOffset<T> | WithPagingCursor<T>

// Mutation (POST / PUT / DELETE)

export const useFoodMutationAsync = <U extends keyof FoodMutationApi>(
  pathname: U,
  options?: {
    overrideAuthType?: FoodAuthType
  }
): ((
  params: FoodMutationApi[U]['params']
) => Promise<FoodMutationApi[U]['response']>) => {
  const authType = foodApiAuthTypes[pathname]

  const accessToken = useAccessToken({
    loginRequired: (options?.overrideAuthType ?? authType) === 'required',
  })

  const f = useCallback(
    async (params: FoodMutationApi[U]['params']) => {
      if (authType === 'required' && accessToken == null) {
        throw new Error('login required')
      }

      const headers: Record<string, string> = {}
      if (authType !== 'none' && accessToken != null) {
        headers.Authorization = `Bearer ${accessToken}`
      }

      const method = foodMutationHttpMethods[pathname]
      if (method === 'DELETE') {
        return await superFetch<FoodMutationApi[U]['response']>(
          config.api.url(pathname, {
            searchParams: snakecaseKeys(params, { deep: true }),
          }),
          {
            method: 'DELETE',
            headers,
          }
        )
      } else {
        // File がある場合
        //   Content-Type を multipart/form-data にする
        //   snakecaseKeys deep=true に食わせると壊れるので、分離してから File 以外の部分だけ deep=true で snakecaseKeys する

        const files = Object.entries(params)
          .map(([k, v]) => (v instanceof File ? [k, v] : null))
          .filter(notNullish)
        const nonFiles = Object.entries(params)
          .map(([k, v]) => (v instanceof File ? null : [k, v]))
          .filter(notNullish)

        const filesParams = Object.fromEntries(files)
        const nonFilesParams = Object.fromEntries(nonFiles)

        const p = snakecaseKeys(filesParams, { deep: false })
        Object.entries(snakecaseKeys(nonFilesParams, { deep: true })).forEach(
          ([k, v]) => {
            p[k] = v
          }
        )

        return await superFetch<FoodMutationApi[U]['response']>(
          config.api.url(pathname),
          {
            method,
            headers,
            contentType:
              files.length > 0
                ? 'multipart/form-data'
                : 'application/x-www-form-urlencoded',
            body: p,
          }
        )
      }
    },
    [authType, accessToken, pathname]
  )

  return f
}

export const useFoodMutation = <U extends keyof FoodMutationApi>(
  pathname: U,
  options?: {
    onSuccess?: (response: FoodMutationApi[U]['response']) => void
    onError?: (error: unknown) => void
    onFinally?: () => void
  }
): ((params: FoodMutationApi[U]['params']) => Promise<void>) => {
  const { showBoundary } = useErrorBoundary()
  const fa = useFoodMutationAsync(pathname)

  const f = useCallback(
    async (params: FoodMutationApi[U]['params']) => {
      try {
        const res = await fa(params)
        options?.onSuccess?.(res)
      } catch (e) {
        console.error(e)

        if (options?.onError) {
          options.onError(e)
        } else {
          showBoundary(e)
        }
      } finally {
        options?.onFinally?.()
      }
    },
    [fa, options, showBoundary]
  )

  return f
}

// GET

export const useFoodSWR = <U extends keyof FoodGetApi>(
  pathname: U,
  params: FoodGetApi[U]['params'],
  options?: {
    disabled?: boolean
    overrideAuthType?: FoodAuthType
    swrOptions?: {
      keepPreviousData?: boolean
    }
  }
): SWRResponse<FoodGetApi[U]['response']> => {
  const authType = options?.overrideAuthType ?? foodApiAuthTypes[pathname]

  const session = useSession({
    loginRequired: authType === 'required',
  })

  const headers: Record<string, string> = {}
  if (authType !== 'none' && session.response != null) {
    headers.Authorization = `Bearer ${session.response.data.accessToken}`
  }

  const url = config.api.url(pathname, {
    searchParams: snakecaseKeys(params, { deep: true }),
  })

  return useSWR(
    // authType が required かつ、session が無い状態ではリクエストを送らない
    // options.disabled が true の場合はリクエストを送らない
    (session.response == null && authType === 'required') || options?.disabled
      ? null
      : [url, session.response?.data.userId ?? 'guest'],
    () => {
      invariant(authType !== 'required' || session.response != null)
      return superFetch<FoodGetApi[U]['response']>(url, {
        method: 'GET',
        headers,
      })
    },
    options?.swrOptions
  )
}

export const useFoodSWRWithErrors = <U extends keyof FoodGetApi>(
  pathname: U,
  params: FoodGetApi[U]['params'],
  options?: {
    allowErrors?: number[]
    disabled?: boolean
    overrideAuthType?: FoodAuthType
  }
): SWRResponse<FoodGetApi[U]['response'] | undefined> => {
  const authType = options?.overrideAuthType ?? foodApiAuthTypes[pathname]

  const session = useSession({
    loginRequired: authType === 'required',
  })

  const headers: Record<string, string> = {}
  if (authType !== 'none' && session.response != null) {
    headers.Authorization = `Bearer ${session.response.data.accessToken}`
  }

  const url = config.api.url(pathname, {
    searchParams: snakecaseKeys(params, { deep: true }),
  })

  return useSWR(
    // authType が required かつ、session が無い状態ではリクエストを送らない
    // options.disabled が true の場合はリクエストを送らない
    (session.response == null && authType === 'required') || options?.disabled
      ? null
      : [url, session.response?.data.userId ?? 'guest'],
    async () => {
      invariant(authType !== 'required' || session.response != null)
      try {
        const res = await superFetch<FoodGetApi[U]['response']>(url, {
          method: 'GET',
          headers,
        })
        return res
      } catch (err) {
        if (
          isSuperFetchError(err) &&
          options?.allowErrors?.includes(err.response.status)
        ) {
          return undefined
        } else {
          throw err
        }
      }
    }
  )
}

export const useFoodSWRInfinite = <U extends keyof FoodGetApi>(
  pathname: U,
  params: FoodGetApi[U]['params'],
  getPaginationParams: (
    pageIndex: number,
    previousPageData: FoodGetApi[U]['response'] | null
  ) => Paging,
  options?: {
    disabled?: boolean
    overrideAuthType?: FoodAuthType
    onSuccess?: () => void
  }
): SWRInfiniteResponse<FoodGetApi[U]['response']> => {
  const authType = options?.overrideAuthType ?? foodApiAuthTypes[pathname]

  const session = useSession({
    loginRequired: authType === 'required',
  })

  const headers: Record<string, string> = {}
  if (authType !== 'none' && session.response != null) {
    headers.Authorization = `Bearer ${session.response.data.accessToken}`
  }

  return useSWRInfinite(
    (pageIndex, previousPageData) => {
      const paginationParams = getPaginationParams(pageIndex, previousPageData)
      // authType が required かつ、session が無い状態ではリクエストを送らない
      // options.disabled が true の場合はリクエストを送らない
      return options?.disabled ||
        (session.response == null && authType === 'required')
        ? null
        : [
            config.api.url(pathname, {
              searchParams: snakecaseKeys(
                {
                  ...params,
                  ...paginationParams,
                },
                { deep: true }
              ),
            }),
            paginationParams,
            session.response?.data.userId ?? 'guest',
          ]
    },
    ([, paginationParams]) => {
      invariant(authType !== 'required' || session.response != null)
      return superFetch<FoodGetApi[U]['response']>(
        config.api.url(pathname, {
          searchParams: snakecaseKeys(
            {
              ...params,
              ...paginationParams,
            },
            { deep: true }
          ),
        }),
        {
          method: 'GET',
          headers,
        }
      )
    },
    {
      onSuccess: () => {
        if (options?.onSuccess) {
          options.onSuccess()
        }
      },
    }
  )
}
