import { createContext, useContext, useRef, useState } from 'react'

import { useErrorBoundary } from 'react-error-boundary'

import { useFoodMutationAsync } from '~/api-client/food_api'
import { Button } from '~/components/action/Button'
import { Modal } from '~/components/action/Modal'
import { TextArea } from '~/components/data-input/TextField'

type ReportRequest =
  | { type: 'dinner'; dinnerId: string }
  | { type: 'tweet'; tweetId: string }
  | { type: 'tweet_reply'; tweetReplyId: string }

type ReportDialog = {
  show: (type: ReportRequest) => void
}

const ReportDialogContext = createContext<ReportDialog>({
  show: () => {},
})

export const ReportDialogProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const { showBoundary } = useErrorBoundary()

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [showDoneDialog, setShowDoneDialog] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [reportRequest, setReportRequest] = useState<ReportRequest>()

  // app 全体を囲むので、authType required にすると必ずダイアログが出てしまうので
  // overrideAuthType: 'optional' にしておく。
  const reportDinner = useFoodMutationAsync('/dinner/report_dinner', {
    overrideAuthType: 'optional',
  })
  const reportTweet = useFoodMutationAsync('/tweet/report_tweet', {
    overrideAuthType: 'optional',
  })
  const reportReply = useFoodMutationAsync('/tweet/report_reply', {
    overrideAuthType: 'optional',
  })

  return (
    <ReportDialogContext.Provider
      value={{
        show: (req) => {
          setReportRequest(req)
        },
      }}
    >
      <Modal
        isOpen={showDoneDialog}
        setIsOpen={(v) => {
          if (!v) {
            setShowDoneDialog(false)
          }
        }}
        title="通報が完了しました。"
      />
      <Modal
        isOpen={reportRequest != null}
        setIsOpen={(v) => {
          if (!v) {
            setReportRequest(undefined)
          }
        }}
        title="通報"
        body={
          <div>
            <TextArea
              inputProps={{
                ref: textareaRef,
                placeholder:
                  '通報内容を詳細に記述してください。事実確認をし、問題があれば規約に則って対応いたします。',
                rows: 6,
              }}
            />
            <div className="text-sm text-[#C60017] mt-1">
              <div>{error}</div>
            </div>
          </div>
        }
        actionButtons={
          <div className="flex mt-6 gap-x-4">
            <Button
              type="button"
              variant="functionWeak"
              className="w-full"
              onClick={() => {
                if (textareaRef.current == null || reportRequest == null) {
                  showBoundary('')
                  return
                }
                textareaRef.current.value = ''
                setReportRequest(undefined)
              }}
            >
              キャンセル
            </Button>
            <Button
              type="button"
              variant="primary"
              className="w-full"
              onClick={async () => {
                setError(undefined)

                if (textareaRef.current == null || reportRequest == null) {
                  showBoundary('')
                  return
                }
                if (textareaRef.current.value.length < 10) {
                  setError('10文字以上入力してください')
                  return
                }
                switch (reportRequest.type) {
                  case 'dinner':
                    await reportDinner({
                      dinnerId: reportRequest.dinnerId,
                      text: textareaRef.current.value,
                    })
                    break
                  case 'tweet':
                    await reportTweet({
                      tweetId: reportRequest.tweetId,
                      text: textareaRef.current.value,
                    })
                    break
                  case 'tweet_reply':
                    await reportReply({
                      tweetReplyId: reportRequest.tweetReplyId,
                      text: textareaRef.current.value,
                    })
                    break
                }
                textareaRef.current.value = ''
                setError(undefined)
                setReportRequest(undefined)
                setShowDoneDialog(true)
              }}
            >
              通報する
            </Button>
          </div>
        }
      />
      {props.children}
    </ReportDialogContext.Provider>
  )
}

export const useReportDialog = () => {
  const ctx = useContext(ReportDialogContext)
  return ctx
}
