import camelcaseKeys from 'camelcase-keys'
import { z } from 'zod'

import { ErrorResponse } from '~/api-definition/response'

const ErrorResponseSchema = z.object({
  code: z.number(),
  errorType: z
    .enum(['token', 'error', 'devel', 'soft_banned'])
    .transform((v) => {
      switch (v) {
        case 'token':
          return 'token'
        case 'error':
          return 'error'
        case 'devel':
          return 'devel'
        case 'soft_banned':
          return 'soft_banned'
      }
    }),
  message: z.string(),
  // FIXME
  // develMessage: z.string(),
})

export const parseErrorResponseText = (args: {
  body: string
}): Omit<ErrorResponse, 'develMessage'> => {
  try {
    const err = ErrorResponseSchema.parse(
      camelcaseKeys(JSON.parse(args.body), { deep: true })
    )
    // 基本的にクライアント側のエラーは message が空文字の場合がある
    // 例: {"code":400,"error_type":"devel","message":"","dev_message":"Parameters are invalid."}
    // ユーザ側へは一律で「予期せぬエラーが発生しました。」を返却する
    if (err.message === '') {
      err.message = '予期せぬエラーが発生しました。'
    }

    return err
  } catch (e) {
    console.error('failed to parse error response', e)
    return {
      code: 500,
      errorType: 'error',
      message: '予期せぬエラーが発生しました。',
      // develMessage: '',
    }
  }
}
