import { createContext, useContext } from 'react'

import { useErrorBoundary } from 'react-error-boundary'
import useSWR from 'swr'
import invariant from 'tiny-invariant'

import {
  userGetUnreadBadgeList,
  userMarkUnreadBadge,
} from '~/api-client/food_api/user/unread_badge'
import { UnreadBadgeType } from '~/api-definition/enumTypes'
import { UnreadBadgeListResponse } from '~/api-definition/response'
import { config } from '~/config'

import { useAccessToken, useSession } from './SessionProvider'

export type UnreadBadge = {
  userId: string
  accessToken: string
  isLoggedIn: boolean
}

const useUserUnreadBadgeListSwr = () => {
  // 利用規約やトップページでもリクエストされるので、loginRequired はつけない
  const session = useSession()
  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/unread_badge_list')
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userGetUnreadBadgeList({
        accessToken: session.response?.data?.accessToken,
      })
    }
  )
}

const UnreadBadgeContext = createContext<{
  response: UnreadBadgeListResponse | undefined
  refresh: () => Promise<void>
  markAsRead: (type: UnreadBadgeType) => Promise<void>
}>({
  response: undefined,
  refresh: async () => {},
  markAsRead: async () => {},
})

export const UnreadBadgeProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const { showBoundary } = useErrorBoundary()

  const accessToken = useAccessToken()
  const unreadBadgeList = useUserUnreadBadgeListSwr()

  const reloadUnreadBadgeList = async () => {
    window.inapp?.delegateReloadUnreadBadgeList?.()
    await unreadBadgeList.mutate()
  }

  return (
    <UnreadBadgeContext.Provider
      value={{
        response: unreadBadgeList.data,
        refresh: reloadUnreadBadgeList,
        markAsRead: async (unreadBadgeType) => {
          try {
            if (accessToken) {
              await userMarkUnreadBadge({
                accessToken,
                body: { unread_badge_type: unreadBadgeType },
              })
              await reloadUnreadBadgeList()
            }
          } catch (e) {
            showBoundary(e)
          }
        },
      }}
    >
      {props.children}
    </UnreadBadgeContext.Provider>
  )
}

export const useUnreadBadge = () => {
  const ctx = useContext(UnreadBadgeContext)
  return ctx
}
