import { superFetch } from '~/api-client'
import { UnreadBadgeType } from '~/api-definition/enumTypes'
import { UnreadBadgeListResponse } from '~/api-definition/response'
import { config } from '~/config'

export const userGetUnreadBadgeList = (args: { accessToken: string }) => {
  return superFetch<UnreadBadgeListResponse>(
    config.api.url('/user/unread_badge_list'),
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${args.accessToken}`,
      },
    }
  )
}

export const userMarkUnreadBadge = (args: {
  accessToken: string
  body: {
    unread_badge_type: UnreadBadgeType
  }
}) => {
  return superFetch(config.api.url('/user/mark_unread_badge'), {
    headers: {
      Authorization: `Bearer ${args.accessToken}`,
    },
    method: 'PUT',
    contentType: 'application/x-www-form-urlencoded',
    body: args.body,
  })
}
