import { createContext, useContext, useState } from 'react'

import { useRouter } from 'next/router'

import { useErrorBoundary } from 'react-error-boundary'

import { chatGetOrCreateChatSpace } from '~/api-client/food_api/chat'
import { Button } from '~/components/action/Button'
import { Modal } from '~/components/action/Modal'
import { goToChatGiftsPage, haveDmChatSpace } from '~/libs/chat'

import { useApplication } from './ApplicationProvider'
import { useAccessToken } from './SessionProvider'

type DmNavigation = {
  open: (userId: string) => void
}

const DmNavigationContext = createContext<DmNavigation>({
  open: () => {},
})

export const DmNavigationProvider: React.FC<{
  children: React.ReactNode
}> = (props) => {
  const { showBoundary } = useErrorBoundary()

  const [userId, setUserId] = useState<string>()
  const app = useApplication()
  const router = useRouter()
  const accessToken = useAccessToken()

  return (
    <DmNavigationContext.Provider
      value={{
        open: async (userId) => {
          if (accessToken == null) return
          const isDmChatSpace = await haveDmChatSpace(accessToken, userId)
          if (!isDmChatSpace) {
            setUserId(userId)
          } else {
            try {
              if (accessToken) {
                const chatSpace = await chatGetOrCreateChatSpace({
                  accessToken,
                  pairUserId: userId,
                })
                goToChatGiftsPage(app, router, chatSpace.space)
              }
            } catch (e) {
              showBoundary(e)
            }
          }
        },
      }}
    >
      <Modal
        isOpen={userId != null}
        setIsOpen={(v) => !v && setUserId(undefined)}
        title={'メッセージルームを作成しますか？'}
        actionButtons={
          <div className="grid grid-cols-2 mt-8 gap-x-4">
            <Button
              type="button"
              variant="functionWeak"
              shrink
              onClick={async (e) => {
                e.stopPropagation()
                setUserId(undefined)
              }}
            >
              キャンセル
            </Button>
            <Button
              type="button"
              variant="primary"
              shrink
              onClick={async (e) => {
                e.stopPropagation()
                try {
                  if (accessToken == null) return
                  if (userId == null) return
                  const chatSpace = await chatGetOrCreateChatSpace({
                    accessToken,
                    pairUserId: userId,
                  })

                  goToChatGiftsPage(app, router, chatSpace.space)
                } catch (e) {
                  showBoundary(e)
                } finally {
                  setUserId(undefined)
                }
              }}
            >
              作成する
            </Button>
          </div>
        }
      />
      {props.children}
    </DmNavigationContext.Provider>
  )
}

export const useDmNavigation = () => {
  const ctx = useContext(DmNavigationContext)
  return ctx
}
