import invariant from 'tiny-invariant'

import { JsonValue } from './api-client/food_api/api-definition'

export type FoodEnv = 'sandbox' | 'dev' | 'prod'

export const config = {
  isProduction: process.env.NODE_ENV === 'production',
  foodEnv: (process.env.NEXT_PUBLIC_FOOD_ENV ?? 'sandbox') as FoodEnv,
  sentry: {
    // 環境別に分ける必要がなく、秘匿情報でもないので一旦ハードコード
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  get baseUrl() {
    return (
      process.env.NEXT_PUBLIC_WWW_BASE_URL ??
      (config.foodEnv === 'prod'
        ? 'https://www.foodies-prime.com'
        : `https://www.${config.foodEnv}.foodies-prime.com`)
    )
  },
  invitationUrl: (type: 'dinner' | 'user', code: string) => {
    return `${config.baseUrl}/invite/${code}?t=${type}`
  },
  loadingImageUrl: {
    main: '/images/loading.svg',
  },
  noImageUrl: {
    userProfileIcon: '/images/noimage_user.svg',
    restaurantCoverImage: '/images/noimage.svg',
    chatImage: '/images/no-image.png',
    tweetImage: '/images/no-image.png',
    chatGroupIcon: '/images/no-image.png',
  },
  pageSize: {
    chatSpaceList: 10,
    chatMessageList: 20,
    dinnerList: 20,
    serviceNoticeList: 20,
    userNotification: 20,
    userList: 20,
    userRecommendedList: 50,
    userDinnerList: 20,
    userSearchList: 50,
    userInvitedUserList: 20,
    userPointDealList: 20,
    userFollowList: 20,
    userFollowerList: 20,
    tweetList: 20,
    chargesList: 20,
    restaurantList: 20,
  },
  cookie: {
    name: 'food_sess',
    get secret() {
      invariant(process.env.COOKIE_SECRET != null, 'COOKIE_SECRET is required')
      invariant(
        process.env.COOKIE_SECRET.length >= 32,
        'Error: iron-session: Bad usage. Password must be at least 32 characters long.'
      )
      return process.env.COOKIE_SECRET
    },
  },
  loginUrl: {
    line(redirectUri: string = '/dinners'): string {
      const params = new URLSearchParams()
      params.append('redirect_uri', redirectUri)
      return `/oauth/login/line?${params.toString()}`
    },
  },
  stripe: {
    get publicKey(): string {
      return process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY ?? ''
    },
  },
  googleMap: {
    get apiKey(): string {
      return process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY ?? ''
    },
  },
  gtm: {
    get id(): string {
      return process.env.GTM_ID ?? 'GTM-W7T8ZW4X'
    },
  },
  line: {
    apiGetTokenUrl: 'https://api.line.me/oauth2/v2.1/token',
    loginUrl(
      state: string,
      redirectUri: string,
      opts?: { dinnerInvitationCode: string } | { userInvitationCode: string }
    ): string {
      const url = new URL('https://access.line.me/oauth2/v2.1/authorize')
      url.searchParams.append('response_type', 'code')
      url.searchParams.append('client_id', this.channelId)
      url.searchParams.append(
        'redirect_uri',
        this.redirectUri(redirectUri, opts)
      )
      url.searchParams.append('state', state)
      url.searchParams.append('scope', 'openid profile')
      return url.toString()
    },
    get channelId(): string {
      invariant(
        process.env.NEXT_PUBLIC_LINE_CHANNEL_ID != null,
        'NEXT_PUBLIC_LINE_CHANNEL_ID is required'
      )
      return process.env.NEXT_PUBLIC_LINE_CHANNEL_ID
    },
    get officialAccountUrl(): string {
      return (
        process.env.NEXT_PUBLIC_LINE_OFFICIAL_ACCOUNT_URL ??
        'https://lin.ee/67ZJ06Y'
      )
    },
    redirectUri(
      redirectUri?: string,
      opts?: { dinnerInvitationCode: string } | { userInvitationCode: string }
    ): string {
      invariant(
        process.env.NEXT_PUBLIC_LINE_REDIRECT_URI != null,
        'NEXT_PUBLIC_LINE_REDIRECT_URI is required'
      )
      const url = new URL(process.env.NEXT_PUBLIC_LINE_REDIRECT_URI)
      if (redirectUri) {
        url.searchParams.append('redirect_uri', redirectUri)
      }
      if (opts) {
        if ('dinnerInvitationCode' in opts) {
          url.searchParams.append(
            'dinner_invitation_code',
            opts.dinnerInvitationCode
          )
        } else {
          url.searchParams.append(
            'user_invitation_code',
            opts.userInvitationCode
          )
        }
      }
      return url.toString()
    },
  },
  api: {
    url(
      pathname: string,
      opts?: {
        searchParams?: Record<string, JsonValue | undefined>
      }
    ): string {
      const url = new URL(
        process.env.API_BASE_URL ??
          process.env.NEXT_PUBLIC_API_BASE_URL ??
          'http://localhost:3050'
      )
      url.pathname = pathname
      if (opts?.searchParams) {
        const params = new URLSearchParams()
        Object.entries(opts.searchParams).forEach(([k, v]) => {
          if (v != null) {
            switch (typeof v) {
              case 'string':
                params.append(k, v)
                break
              case 'boolean':
                params.append(k, String(v))
                break
              case 'number':
                params.append(k, String(v))
                break
              case 'object':
                params.append(k, JSON.stringify(v))
                break
            }
          }
        })
        url.search = params.toString()
      }
      return url.toString()
    },
  },
  applyUrl:
    'https://docs.google.com/forms/d/e/1FAIpQLSc9Lwa5Q8wRSj6HoaBI4mP6CBXBxJHCnNcKJjR2hDCID0XNmg/viewform',
  appstoreUrl:
    'https://apps.apple.com/jp/app/foodies-prime-%E3%83%95%E3%83%BC%E3%83%87%E3%82%A3%E3%83%BC%E3%82%BA-%E3%83%97%E3%83%A9%E3%82%A4%E3%83%A0/id6456452744',
  googleplayUrl:
    'https://play.google.com/store/search?q=foodies+prime&c=apps&hl=ja-JP',
}
