import Image from 'next/image'

import Addition01Icon from './svg/Addition01.svg'
import Addition02Icon from './svg/Addition02.svg'
import AdditionRedIcon from './svg/Addition_red.svg'
import ArrowDoubleIcon from './svg/ArrowDouble.svg'
import ArrowDownIcon from './svg/ArrowDown.svg'
import ArrowDownTriangleIcon from './svg/ArrowDownTriangle.svg'
import ArrowLeftIcon from './svg/ArrowLeft.svg'
import ArrowLeftSmallIcon from './svg/ArrowLeftSmall.svg'
import ArrowRightIcon from './svg/ArrowRight.svg'
import ArrowRightSmallIcon from './svg/ArrowRightSmall.svg'
import BlacklistIcon from './svg/Blacklist.svg'
import Camera2Icon from './svg/Camera2.svg'
import CameraIcon from './svg/Camera.svg'
import CheckIcon from './svg/Check.svg'
import ChevronLeftIcon from './svg/Chevron_left.svg'
import ChevronRightIcon from './svg/Chevron_right.svg'
import CloseIcon from './svg/Close.svg'
import CopyIcon from './svg/Copy.svg'
import CrownIcon from './svg/Crown.svg'
import DeleteIcon from './svg/Delete.svg'
import DictionaryIcon from './svg/Dictionary.svg'
import EditIcon from './svg/Edit.svg'
import FacebookIcon from './svg/Facebook.svg'
import GiftIcon from './svg/Gift.svg'
import GuestIcon from './svg/Guest.svg'
import HeartIcon from './svg/Heart.svg'
import HomeIcon from './svg/Home.svg'
import InfoIcon from './svg/Info.svg'
import InstagramIcon from './svg/Instagram.svg'
import InstagramTreadsIcon from './svg/InstagramTreads.svg'
import LinkIcon from './svg/Link.svg'
import LinkBlackIcon from './svg/LinkBlack.svg'
import LitlinkIcon from './svg/Litlink.svg'
import MapIcon from './svg/Map.svg'
import MemoIcon from './svg/Memo.svg'
import MenuIcon from './svg/Menu.svg'
import MessageIcon from './svg/Message.svg'
import MessagePlusIcon from './svg/MessagePlus.svg'
import MypageIcon from './svg/Mypage.svg'
import NotificationIcon from './svg/Notification.svg'
import OtwindowIcon from './svg/Otwindow.svg'
import PhoneIcon from './svg/Phone.svg'
import PhotoIcon from './svg/Photo.svg'
import PhotoGalleryIcon from './svg/PhotoGallery.svg'
import PlusIcon from './svg/Plus.svg'
import PriceIcon from './svg/Price.svg'
import ReplyIcon from './svg/Reply.svg'
import ReportIcon from './svg/Report.svg'
import SearchIcon from './svg/Search.svg'
import SendIcon from './svg/Send.svg'
import TabelogIcon from './svg/Tabelog.svg'
import TiktokIcon from './svg/Tiktok.svg'
import TipsIcon from './svg/Tips.svg'
import TriangleDownIcon from './svg/TriangleDown.svg'
import TwitterIcon from './svg/Twitter.svg'
import UserIcon from './svg/User.svg'
import UserPlusIcon from './svg/UserPlus.svg'
import UserWarningStatusIcon from './svg/UserWarningStatus.svg'
import UsersIcon from './svg/Users.svg'
import YoutubeIcon from './svg/Youtube.svg'

export const CalendarIcon = ({ className }: { className?: string }) => {
  return (
    <Image
      unoptimized
      src="/icons/calendar.svg"
      alt="calendar"
      width={16}
      height={16}
      className={className}
    />
  )
}

export {
  DictionaryIcon,
  UserWarningStatusIcon,
  OtwindowIcon,
  InfoIcon,
  CheckIcon,
  AdditionRedIcon,
  Addition01Icon,
  Addition02Icon,
  CloseIcon,
  CopyIcon,
  CrownIcon,
  DeleteIcon,
  EditIcon,
  GiftIcon,
  GuestIcon,
  HeartIcon,
  HomeIcon,
  InstagramIcon,
  InstagramTreadsIcon,
  MapIcon,
  MenuIcon,
  MemoIcon,
  MessageIcon,
  MessagePlusIcon,
  MypageIcon,
  NotificationIcon,
  PhotoIcon,
  PlusIcon,
  PriceIcon,
  ReplyIcon,
  SearchIcon,
  TipsIcon,
  TwitterIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PhoneIcon,
  CameraIcon,
  Camera2Icon,
  UserIcon,
  UsersIcon,
  UserPlusIcon,
  PhotoGalleryIcon,
  SendIcon,
  ArrowDownIcon,
  ArrowDownTriangleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowLeftSmallIcon,
  ArrowRightSmallIcon,
  ArrowDoubleIcon,
  LinkIcon,
  LinkBlackIcon,
  LitlinkIcon,
  BlacklistIcon,
  FacebookIcon,
  YoutubeIcon,
  TiktokIcon,
  TabelogIcon,
  ReportIcon,
  TriangleDownIcon,
}
