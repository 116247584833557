import useSWR from 'swr'
import invariant from 'tiny-invariant'

import { PaymentCard } from '~/api-definition/definition'
import { RestaurantPhotoClipPrivacyType } from '~/api-definition/enumTypes'
import {
  AutoChargeSuggestionResponse,
  DinnerAttendeesResponse,
  GiftedUsersResponse,
  OwnedGiftsResponse,
  OwnedPointsResponse,
  PaymentCardResponse,
  PaymentCardsResponse,
  RestaurantPhotoClipsResponse,
  SuccessResponse,
  UserContactResponse,
  UserMemoResponse,
  UserResponse,
  UsersResponse,
} from '~/api-definition/response'
import { config } from '~/config'
import { useSession } from '~/provider/SessionProvider'

import { Paging } from '.'
import { superFetch } from '..'

export const userSearchUsers = (args: {
  accessToken?: string
  searchParams: { key: string }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<UsersResponse>(
    config.api.url('/user/search_users', {
      searchParams: args.searchParams,
    }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userRecommendedUsers = (args: {
  accessToken: string
  searchParams: { count?: number }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<UsersResponse>(
    config.api.url('/user/recommended_users', {
      searchParams: args.searchParams,
    }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userGetAvailableUserName = (args: {
  accessToken: string
  searchParams: { user_name: string }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<SuccessResponse>(
    config.api.url('/user/available_user_name', {
      searchParams: args.searchParams,
    }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userGetMe = (args: { accessToken: string }) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<{ user: UserResponse['user'] & { birthday?: string } }>(
    config.api.url('/user/me'),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userGetProfile = (args: {
  accessToken?: string
  searchParams:
    | {
        user_id: string
      }
    | { user_name: string }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<UserResponse>(
    config.api.url('/user/profile', { searchParams: args.searchParams }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userUpdateProfile = (args: {
  accessToken: string
  body: {
    user_name?: string
    display_name?: string
    biography?: string
    birthday?: string
    unset_birthday?: boolean
    show_age?: boolean
    sex?: string
    show_sex?: boolean
    user_region_id?: string
    unset_user_region?: boolean
    show_region?: boolean
    unfavorite_foods?: string
    allergic_foods?: string
    agreed_terms?: true
    alcohol_amount_type?: string
    favorite_alcohol?: string
  }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }

  if (typeof args.body.birthday !== 'undefined') {
    args.body.show_age = true
  }

  if (typeof args.body.sex !== 'undefined') {
    args.body.show_sex = true
  }

  if (typeof args.body.user_region_id !== 'undefined') {
    args.body.show_region = true
  }

  return superFetch<UserResponse>(config.api.url('/user/update_profile'), {
    method: 'PUT',
    contentType: 'application/x-www-form-urlencoded',
    headers,
    body: args.body,
  })
}

export const userUpdateSocialId = (args: {
  accessToken: string
  body: {
    instagram_user_id?: string
    facebook_user_id?: string
    twitter_user_id?: string
    tabelog_user_id?: string
    youtube_channel_id?: string
    tiktok_user_id?: string
    using_instagram_threads?: boolean
  }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<UserResponse>(config.api.url('/user/update_social_id'), {
    method: 'PUT',
    contentType: 'application/x-www-form-urlencoded',
    headers,
    body: args.body,
  })
}

export const userUserMemo = (args: {
  accessToken?: string
  searchParams: {
    user_id?: string
    user_name?: string
  }
}) => {
  const headers: Record<string, string> = {}
  if (args?.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<UserMemoResponse>(
    config.api.url('/user/user_memo', { searchParams: args.searchParams }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userUpdateUserMemo = (args: {
  accessToken: string
  body: {
    user_id: string
    text?: string
    unset_text?: boolean
  }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<UserMemoResponse>(
    config.api.url('/user/update_user_memo'),
    {
      method: 'PUT',
      contentType: 'application/x-www-form-urlencoded',
      headers,
      body: args.body,
    }
  )
}

export const userUpdateProfileIcon = (args: {
  accessToken: string
  body: {
    image_file: File
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<UserResponse>(config.api.url('/user/update_profile_icon'), {
    method: 'PUT',
    contentType: 'multipart/form-data',
    headers,
    body: args.body,
  })
}

export const userGetPaymentCards = (args: { accessToken?: string }) => {
  const headers: Record<string, string> = {}
  if (args?.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<PaymentCardsResponse>(
    config.api.url('/user/payment_cards'),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userNewPaymentCard = (args: {
  accessToken: string
  body: {
    stripe_card_token: string
    as_default?: boolean
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<PaymentCardResponse>(
    config.api.url('/user/new_payment_card'),
    {
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      headers,
      body: args.body,
    }
  )
}

export const userDeletePaymentCard = (args: {
  accessToken: string
  searchParams: {
    payment_card_id: string
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<PaymentCardResponse>(
    config.api.url('/user/delete_payment_card', {
      searchParams: args.searchParams,
    }),
    {
      method: 'DELETE',
      headers,
    }
  )
}

export const userDefaultPaymentCard = (args: {
  accessToken: string
  body: {
    payment_card_id: string
  }
}) => {
  const headers: Record<string, string> = {}
  if (args.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<PaymentCardResponse>(
    config.api.url('/user/default_payment_card'),
    {
      method: 'PUT',
      contentType: 'application/x-www-form-urlencoded',
      headers,
      body: args.body,
    }
  )
}

export const userGetFollowingUsersFetcher =
  (accessToken: string) => (args: { searchParams: { user_id: string } }) => {
    const cfg = {
      url: (paging: Paging) => {
        return config.api.url('/user/following_users', {
          searchParams: {
            ...args.searchParams,
            ...paging,
          },
        })
      },
      fetcher: (paging: Paging) => {
        return superFetch<UsersResponse>(cfg.url(paging), {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      },
    }
    return cfg
  }

/*
export const chatGetChatMessagesFetcher =
  (accessToken: string) =>
  (args: {
    searchParams: {
      chat_space_id: string
    }
  }) => {
    const cfg = {
      url: (paging: Paging) => {
        return config.api.url('/chat/chat_messages', {
          searchParams: {
            ...args.searchParams,
            ...paging,
          },
        })
      },
      fetcher: (paging: Paging) => {
        return superFetch<ChatMessagesResponse>(cfg.url(paging), {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      },
    }
    return cfg
  }
*/

export const userFollowUser = (args: {
  accessToken: string
  body: {
    user_id: string
  }
}) => {
  const headers: Record<string, string> = {}
  headers['Authorization'] = `Bearer ${args.accessToken}`
  return superFetch<PaymentCardResponse>(config.api.url('/user/follow_user'), {
    method: 'POST',
    contentType: 'application/x-www-form-urlencoded',
    headers,
    body: args.body,
  })
}

export const userUnfollowUser = (args: {
  accessToken: string
  searchParams: {
    user_id: string
  }
}) => {
  const headers: Record<string, string> = {}
  headers['Authorization'] = `Bearer ${args.accessToken}`
  return superFetch<PaymentCardResponse>(
    config.api.url('/user/unfollow_user', { searchParams: args.searchParams }),
    {
      method: 'DELETE',
      headers,
    }
  )
}

export const userDeleteFollowerUser = (args: {
  accessToken: string
  searchParams: {
    user_id: string
  }
}) => {
  const headers: Record<string, string> = {}
  headers['Authorization'] = `Bearer ${args.accessToken}`
  return superFetch<PaymentCardResponse>(
    config.api.url('/user/delete_followed_user', {
      searchParams: args.searchParams,
    }),
    {
      method: 'DELETE',
      headers,
    }
  )
}

export const userGetDinnerAttendees = (args: { accessToken?: string }) => {
  const headers: Record<string, string> = {}
  if (args?.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<DinnerAttendeesResponse>(
    config.api.url('/user/dinner_attendees'),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userGetOwnedGifts = (
  args:
    | { accessToken: string; searchParams?: { user_id: string } }
    | {
        searchParams: { user_id: string }
      }
) => {
  const headers: Record<string, string> = {}
  if ('accessToken' in args) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<OwnedGiftsResponse>(
    config.api.url('/user/owned_gifts', {
      searchParams: args.searchParams,
    }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userGetGiftedUsers = (args: {
  accessToken: string
  searchParams: {
    gift_id: string
  }
}) => {
  const headers: Record<string, string> = {}
  if (args?.accessToken) {
    headers['Authorization'] = `Bearer ${args.accessToken}`
  }
  return superFetch<GiftedUsersResponse>(
    config.api.url('/user/gifted_users', {
      searchParams: args.searchParams,
    }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userNewOwnedPoint = (args: {
  accessToken: string
  body: {
    payment_card_id: string
    point_amount: number
    jpy_price_amount: number
  }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<OwnedGiftsResponse>(
    config.api.url('/user/new_owned_point'),
    {
      method: 'POST',
      headers,
      contentType: 'multipart/form-data',
      body: args.body,
    }
  )
}

export const userGetOwnedPoints = (args: { accessToken: string }) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<OwnedPointsResponse>(config.api.url('/user/owned_points'), {
    method: 'GET',
    headers,
  })
}

export const userGetAutoChargeSuggestion = (args: {
  accessToken: string
  searchParams: { required_point_amount: number }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<AutoChargeSuggestionResponse>(
    config.api.url('/user/auto_charge_suggestion', {
      searchParams: args.searchParams,
    }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userRestaurantPhotoClips = (args: {
  accessToken: string
  searchParams: {
    privacy_type?: RestaurantPhotoClipPrivacyType
    count?: number
    cursor_text?: string
    cursor_before?: string
  }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<RestaurantPhotoClipsResponse>(
    config.api.url('/user/restaurant_photo_clips', {
      searchParams: args.searchParams,
    }),
    {
      method: 'GET',
      headers,
    }
  )
}

export const userNewUserContact = (args: {
  accessToken: string
  body: {
    user_contact_type: string
    body_text: string
  }
}) => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${args.accessToken}`,
  }
  return superFetch<UserContactResponse>(
    config.api.url('/user/new_user_contact'),
    {
      method: 'POST',
      headers,
      contentType: 'application/x-www-form-urlencoded',
      body: args.body,
    }
  )
}

// Hooks

export const useUserSearchUsers = (args: { key: string }) => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    args.key === ''
      ? null
      : config.api.url('/user/search_users', {
          searchParams: { key: args.key },
        }),
    () =>
      userSearchUsers({
        accessToken: session.response?.data?.accessToken,
        searchParams: {
          key: args.key,
        },
      })
  )
}

export const useUserRecommendedUsers = () => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/recommended_users')
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userRecommendedUsers({
        accessToken: session.response?.data?.accessToken,
        searchParams: {
          count: config.pageSize.userRecommendedList,
        },
      })
    }
  )
}

export const useUserPaymentCards = () => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/payment_cards')
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userGetPaymentCards({
        accessToken: session.response?.data?.accessToken,
      })
    }
  )
}

export const useUserDefaultPaymentCard = () => {
  const paymentCards = useUserPaymentCards()
  if (paymentCards.data == null) {
    return { status: 'loading' as const, paymentCard: undefined }
  }

  return {
    status: 'loaded' as const,
    // FIXME: noUncheckedIndexedAccess を設定するまでは明示的に | undefined を付与する

    paymentCard:
      paymentCards.data.cards.find((c) => c.selected) ??
      (paymentCards.data.cards[0] as PaymentCard | undefined),
  }
}

export const useUserMe = () => {
  const session = useSession({ loginRequired: true })
  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/me')
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userGetMe({
        accessToken: session.response?.data?.accessToken,
      })
    }
  )
}

export const isUserDinnerParamsKey = (
  s: any
): s is keyof typeof userDinnerParams => {
  return Object.keys(userDinnerParams).includes(s)
}

export const userDinnerParams = {
  organizing: (userId?: string) =>
    ({
      userId,
      passedScheduledAt: false,
      memberFilter: 'organized',
      count: config.pageSize.userDinnerList,
      sortBy: 'scheduled_at_asc',
    } as const),
  attending: (userId?: string) =>
    ({
      userId,
      passedScheduledAt: false,
      memberFilter: 'attended',
      count: config.pageSize.userDinnerList,
      sortBy: 'scheduled_at_asc',
    } as const),
  applied: (userId?: string) =>
    ({
      userId,
      passedScheduledAt: false,
      memberFilter: 'applied',
      count: config.pageSize.userDinnerList,
      sortBy: 'scheduled_at_asc',
    } as const),
  invited: (userId?: string) =>
    ({
      userId,
      passedScheduledAt: false,
      memberFilter: 'invited',
      count: config.pageSize.userDinnerList,
      sortBy: 'scheduled_at_asc',
    } as const),
  organized: (userId?: string) =>
    ({
      userId,
      passedScheduledAt: true,
      memberFilter: 'organized',
      count: config.pageSize.userDinnerList,
      sortBy: 'scheduled_at_desc',
    } as const),
  attended: (userId?: string) =>
    ({
      userId,
      passedScheduledAt: true,
      memberFilter: 'attended',
      count: config.pageSize.userDinnerList,
      sortBy: 'scheduled_at_desc',
    } as const),
  satWithMe: (attendedUserId: string) =>
    ({
      attendedUserId: attendedUserId,
      passedScheduledAt: true,
      memberFilter: 'attended,organized',
      sortBy: 'scheduled_at_desc',
    } as const),
} as const

export const useUserDinnerAttendees = () => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/dinner_attendees')
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userGetDinnerAttendees({
        accessToken: session.response?.data?.accessToken,
      })
    }
  )
}

export const useUserOwnedGifts = (args: {
  searchParams: { user_id: string }
}) => {
  return useSWR(
    config.api.url('/user/owned_gifts', {
      searchParams: args.searchParams,
    }),
    () => {
      return userGetOwnedGifts(args)
    }
  )
}

export const useUserMeOwnedGifts = () => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/owned_gifts')
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userGetOwnedGifts({
        accessToken: session.response?.data?.accessToken,
      })
    }
  )
}

export const useUserGiftedUsers = (args: {
  searchParams: { gift_id: string }
}) => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/gifted_users')
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userGetGiftedUsers({
        accessToken: session.response?.data?.accessToken,
        searchParams: args.searchParams,
      })
    }
  )
}

export const useUserOwnedPoints = () => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/owned_points')
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userGetOwnedPoints({
        accessToken: session.response?.data?.accessToken,
      })
    }
  )
}

export const useUserAutoChargeSuggestion = (args: {
  searchParams: { required_point_amount: number }
}) => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/auto_charge_suggestion', {
          searchParams: args.searchParams,
        })
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userGetAutoChargeSuggestion({
        accessToken: session.response?.data?.accessToken,
        searchParams: args.searchParams,
      })
    }
  )
}

export const useUserRestaurantPhotoClips = (args: {
  searchParams: {
    privacy_type?: RestaurantPhotoClipPrivacyType
    count?: number
    cursor_text?: string
    cursor_before?: string
  }
}) => {
  const session = useSession({ loginRequired: true })

  return useSWR(
    session.response?.data?.accessToken != null
      ? config.api.url('/user/restaurant_photo_clips', {
          searchParams: args.searchParams,
        })
      : null,
    () => {
      invariant(session.response?.data?.accessToken != null)
      return userRestaurantPhotoClips({
        accessToken: session.response?.data?.accessToken,
        searchParams: args.searchParams,
      })
    }
  )
}

// TODO: user.ts 分割したい
// unread_badge と user_notice は分割済み
