import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'

import { useAsync } from 'react-use'

import { superFetch } from '~/api-client'
import { Button } from '~/components/action/Button'
import { Modal } from '~/components/action/Modal'
import { config } from '~/config'

import { useApplication } from './ApplicationProvider'

export type Session = {
  userId: string
  accessToken: string
  isLoggedIn: boolean
}

const SessionContext = createContext<{
  response?: { data: Session }
  reloadSession: () => Promise<void>
  setNewSession: (userId: string, accessToken: string) => Promise<void>
  openLoginDialog: () => Promise<void>
  logout: () => Promise<void>
  deactivateAccount: () => Promise<void>
}>({
  response: undefined,
  reloadSession: async () => {},
  setNewSession: async () => {},
  openLoginDialog: async () => {},
  logout: async () => {},
  deactivateAccount: async () => {},
})

export const SessionProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const router = useRouter()
  const [openLoginDialog, setOpenLoginDialog] = useState(false)
  const application = useApplication()

  const [session, setSession] = useState<Session | null>(null)

  const reloadSession = useCallback(async () => {
    setSession(await superFetch<Session>('/api/session'))
  }, [])

  const setNewSession = useCallback(
    async (userId: string, accessToken: string) => {
      const newSession = await superFetch<Session>('/api/session', {
        method: 'POST',
        contentType: 'application/json',
        body: { userId, accessToken },
      })
      setSession(newSession)
    },
    []
  )

  useAsync(async () => {
    reloadSession()
  }, [])

  const logout = useCallback(async () => {
    if (session == null) return

    if (application.mode === 'inapp') {
      application.delegateSignIn()
    } else {
      try {
        await superFetch(config.api.url('/user/signout'), {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${session.accessToken}`,
          },
        })
      } catch (e) {
        // access token 自体の有効期限が切れている場合などは失敗する場合も
        // 後続の処理に進めないといけないため無視する。
        console.error(e)
      }
      await superFetch('/api/session', { method: 'DELETE' })
      setSession(null)
      await router.push('/')
    }
  }, [application, router, session])

  const deactivateAccount = useCallback(async () => {
    if (session == null) return

    await superFetch(config.api.url('/user/deactivate'), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    })

    logout()
  }, [logout, session])

  const requireLogin = useCallback(async () => {
    setOpenLoginDialog(true)
  }, [])

  return (
    <SessionContext.Provider
      value={{
        response: session ? { data: session } : undefined,
        reloadSession,
        setNewSession,
        openLoginDialog: requireLogin,
        logout,
        deactivateAccount,
      }}
    >
      <Modal
        isOpen={openLoginDialog}
        title="ログインが必要です"
        body=""
        actionButtons={[
          <div key="login" className="flex justify-center">
            {application.mode === 'inapp' && (
              <Button onClick={application.delegateSignIn}>ログイン</Button>
            )}
            {application.mode === 'web' && (
              <Button>
                <Link href={config.loginUrl.line(router.asPath)}>
                  LINE でログイン
                </Link>
              </Button>
            )}
          </div>,
        ]}
      />
      {props.children}
    </SessionContext.Provider>
  )
}

export const useSession = (args?: { loginRequired?: boolean }) => {
  const ctx = useContext(SessionContext)
  useEffect(() => {
    if (args?.loginRequired && ctx.response?.data?.isLoggedIn === false) {
      ctx.openLoginDialog()
    }
  }, [ctx, args])
  return ctx
}

export const useAccessToken = (args?: { loginRequired?: boolean }) => {
  const session = useSession(args)
  return session.response?.data?.accessToken
}
