import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { useApplication } from '~/provider/ApplicationProvider'
import { useSession } from '~/provider/SessionProvider'

type AppHandlerType =
  | 'inAppReady'
  | 'delegateOpenUrl'
  | 'delegateSignIn'
  | 'delegateReloadUnreadBadgeList'
  | 'delegateSaleGiftsForChatSpace'
  | 'delegateSaleGiftsForTweet'
  | 'delegateSaleGiftsForReply'

const callAppHandler = (handler: AppHandlerType, ...args: any[]) => {
  const i = setInterval(() => {
    if (typeof window?.flutter_inappwebview?.callHandler === 'function') {
      clearInterval(i)
      window.flutter_inappwebview.callHandler(handler, ...args)
    }
  }, 200)
}

export const flutterInAppReady = () => {
  callAppHandler('inAppReady')
}

export const useFlutterCallbacks = () => {
  const app = useApplication()
  const session = useSession()
  const router = useRouter()

  const registerFlutterCallbacks = useCallback(() => {
    window.inapp = {
      init: (
        accessToken: string,
        userId: string,
        redirectPath: string,
        options?: { iapGiftEnabled?: boolean; faqEnabled?: boolean }
      ) => {
        app.setIapGiftEnabled(options?.iapGiftEnabled ?? false)
        app.setFaqEnabled(options?.faqEnabled ?? false)
        session
          .setNewSession(userId, accessToken)
          .then(() => {
            router.replace(redirectPath)
          })
          .catch((e) => {
            console.error(e)
            window?.inapp?.delegateSignIn?.()
          })
      },
      setLocale: (locale: string) => {
        app.setLocale(locale)
      },
      saleGiftsCallback: (result: 'purchased' | 'error' | 'canceled') => {
        app.setSaleGiftsResult(result)
      },
      setInstalledApps: (apps: { outlook?: boolean }) => {
        app.setInstalledApps(apps)
      },
      delegateOpenUrl: (url: string) => callAppHandler('delegateOpenUrl', url),
      delegateSignIn: () => callAppHandler('delegateSignIn'),
      delegateReloadUnreadBadgeList: () =>
        callAppHandler('delegateReloadUnreadBadgeList'),
      delegateSaleGiftsForChatSpace: (chatSpaceId: string, toUserId: string) =>
        callAppHandler('delegateSaleGiftsForChatSpace', chatSpaceId, toUserId),
      delegateSaleGiftsForTweet: (tweetId: string) =>
        callAppHandler('delegateSaleGiftsForTweet', tweetId),
      delegateSaleGiftsForReply: (tweetReplyId: string) =>
        callAppHandler('delegateSaleGiftsForReply', tweetReplyId),
    }
  }, [app, router, session])

  return registerFlutterCallbacks
}
