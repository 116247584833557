import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { cx } from '@linaria/core'
import composeRefs from '@seznam/compose-react-refs'
import invariant from 'tiny-invariant'

import { Label } from './Label'

export const TextField: React.FC<{
  label?: React.ReactNode
  labelProps?: ComponentProps<'label'>
  required?: boolean
  leftIcon?: React.ReactNode
  inputProps?: ComponentProps<'input'>
  alternativeInput?: React.ReactNode
  error?: string | string[]
  helperText?: string
  triedSubmit?: boolean
}> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isDirty, setIsDirty] = useState(false)

  const ref = props.inputProps?.ref ?? inputRef
  invariant(typeof ref !== 'string', 'string ref is not allowed')
  invariant(typeof ref !== 'function', 'function ref is not allowed')

  return (
    <div className="w-full">
      {props.label && (
        <Label
          label={props.label}
          labelProps={props.labelProps}
          required={props.required}
        />
      )}
      <div className="relative">
        <div
          className="absolute top-0 bottom-0 left-0"
          onClick={() => {
            ref.current?.focus()
          }}
        >
          <div className="flex items-center h-full ml-4">{props.leftIcon}</div>
        </div>
        <input
          type="text"
          className={cx(
            'px-4 border border-gray rounded text-sm h-[40px] active:border-gray outline-none outline-0 w-full',
            props.leftIcon && 'pl-10',
            props.alternativeInput && 'hidden'
          )}
          {...props.inputProps}
          ref={ref}
          onBlur={(e) => {
            setIsDirty(true)
            if (props.inputProps?.onBlur) {
              props.inputProps?.onBlur(e)
            }
          }}
          onChange={(e) => {
            setIsDirty(true)
            if (props.inputProps?.onChange) {
              props.inputProps?.onChange(e)
            }
          }}
        />
        <div>{props.alternativeInput}</div>
      </div>

      {props.helperText && (
        <div className="text-sm mt-2">{props.helperText}</div>
      )}

      {props.alternativeInput == null &&
        (isDirty || props.triedSubmit) &&
        props.error && (
          <div className="text-sm text-[#C60017] mt-1">
            {typeof props.error === 'string' ? props.error : props.error[0]}
          </div>
        )}
    </div>
  )
}

export const TextArea: React.FC<{
  label?: React.ReactNode
  labelProps?: ComponentProps<'label'>
  required?: boolean
  inputProps?: ComponentProps<'textarea'>
  dynamicHeight?: boolean
  dynamicHeightDisableOnBlur?: boolean
  onChangeHeight?: () => void
  error?: string | string[]
  triedSubmit?: boolean
  textareaClassName?: string
}> = (props) => {
  const { ref, ...inputProps } = props.inputProps ?? {}

  const [isDirty, setIsDirty] = useState(false)
  const [focus, setFocus] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const { onChangeHeight } = props
  const autoResize = useCallback(() => {
    if (textareaRef.current == null) return

    textareaRef.current.style.height = 'auto'

    if (focus || !props.dynamicHeightDisableOnBlur) {
      const computedStyle = getComputedStyle(textareaRef.current, null)
      textareaRef.current.style.height = `${
        parseInt(computedStyle.paddingTop) +
        parseInt(computedStyle.paddingBottom) +
        textareaRef.current.scrollHeight
      }px`
    }
    onChangeHeight?.()
  }, [focus, props.dynamicHeightDisableOnBlur, onChangeHeight])

  useEffect(() => {
    autoResize()
  }, [autoResize])

  invariant(typeof ref !== 'string', 'string ref is not allowed')

  return (
    <div className="w-full">
      {props.label && (
        <Label
          label={props.label}
          labelProps={props.labelProps}
          required={props.required}
        />
      )}
      <textarea
        className={cx(
          'block px-4 py-2 border border-gray rounded text-sm min-h-[40px] active:border-gray outline-0 w-full',
          props.textareaClassName
        )}
        ref={composeRefs(ref, textareaRef)}
        {...inputProps}
        onFocus={(e) => {
          setFocus(true)
          if (props.inputProps?.onFocus) {
            props.inputProps?.onFocus(e)
          }
        }}
        onBlur={(e) => {
          setFocus(false)
          setIsDirty(true)

          autoResize()
          if (props.inputProps?.onBlur) {
            props.inputProps?.onBlur(e)
          }
        }}
        onChange={(e) => {
          setIsDirty(true)

          if (props.dynamicHeight) {
            autoResize()
          }

          if (props.inputProps?.onChange) {
            props.inputProps?.onChange(e)
          }
        }}
      />
      {(isDirty || props.triedSubmit) && props.error && (
        <div className="text-sm text-[#C60017] mt-1">
          {typeof props.error === 'string' ? props.error : props.error[0]}
        </div>
      )}
    </div>
  )
}
